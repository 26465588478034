import { useState } from 'react';
import { lightModeState, userState } from '../../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import swal from 'sweetalert';
import LoadAnimation from '../../universal-decorations/LoadAnimation';

const FeedbackForm = () => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [feedback, setFeedback] = useState<{
    page: string;
    subject: string;
    description: string;
    email?: string;
  }>({
    page: 'Home',
    subject: '',
    description: '',
  });
  let pages: string[] = [];
  pages[0] = 'Home';
  if (user?.role === 'superadmin') pages[1] = 'BMS';
  pages[2] = 'Reports';
  pages[3] = 'Add User';
  pages[4] = 'Change Password';
  pages[5] = 'Others';

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFeedback({ ...feedback, [id]: value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await fetch(
      process.env.REACT_APP_FORMSPREE_ENDPOINT
        ? process.env.REACT_APP_FORMSPREE_ENDPOINT
        : '',
      {
        method: 'POST',
        body: JSON.stringify(feedback),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(() => {
        setIsLoading(false);
        swal('Feedback Sent', 'Thanks for the feedback!', 'success', {
          timer: 1000,
          buttons: [false],
        });
      })
      .catch((err) => swal('Unable to send feedback', err.message, 'error'));
  };

  const onClickCB = () => {
    setIsSendEmail(!isSendEmail);
    if (!isSendEmail) {
      setFeedback({ ...feedback, email: user?.email });
    } else {
      setFeedback({ ...feedback, email: undefined });
    }
  };

  return isLoading ? (
    <div className='mt-32'>
      <LoadAnimation />
    </div>
  ) : (
    <form
      onSubmit={onSubmit}
      className='mx-auto flex flex-col gap-y-5 mt-10 w-full sm:w-1/3 items-center'
    >
      <div className='flex flex-col w-5/6'>
        <label
          htmlFor='page'
          className={`text-left ${
            lightMode ? 'text-gray-800' : 'text-white'
          } mb-2 text-lg`}
        >
          Page:
        </label>
        <select
          id='page'
          onChange={onChange}
          value={feedback.page}
          className='w-full self-center py-2 px-2 rounded cursor-pointer font-semibold focus:outline-none border-r border-l border-t border-b border-gray-400 shadow-md bg-gray-100 text-gray-700'
        >
          {pages.map((page) => (
            <option value={page} key={page} className='font-semibold'>
              {page}
            </option>
          ))}
        </select>
      </div>
      <div className='flex flex-col w-5/6'>
        <label
          htmlFor='subject'
          className={`text-left ${
            lightMode ? 'text-gray-800' : 'text-white'
          } mb-2 text-lg`}
        >
          Subject:
        </label>
        <input
          type='text'
          id='subject'
          placeholder='This is a subject'
          onChange={onChange}
          value={feedback.subject}
          className='w-full self-center py-2 px-2 rounded font-semibold focus:outline-none border-r border-l border-t border-b border-gray-400 shadow-md bg-gray-100 text-gray-700'
          required
        />
      </div>
      <div className='flex flex-col w-5/6'>
        <label
          htmlFor='description'
          className={`text-left ${
            lightMode ? 'text-gray-800' : 'text-white'
          } mb-2 text-lg`}
        >
          Description:
        </label>
        <textarea
          id='description'
          onChange={onChange}
          value={feedback.description}
          placeholder='Write your feedback...'
          className='w-full self-center py-2 px-2 rounded font-semibold focus:outline-none border-r border-l border-t border-b border-gray-400 shadow-md bg-gray-100 text-gray-700'
          rows={8}
          required
        />
      </div>
      <label className='text-sm text-white cursor-pointer w-5/6 flex items-center gap-x-2'>
        <input
          type='checkbox'
          checked={isSendEmail}
          onChange={onClickCB}
          className={`cursor-pointer rounded checkbox border-l border-r border-b border-t ${
            lightMode ? 'border-gray-300' : 'border-white'
          }`}
        />
        <span
          className={`ml-2.5 text-base sm:text-base my-auto ${
            lightMode ? 'text-gray-700' : 'text-gray-200'
          }`}
        >
          Email me an update
        </span>
      </label>
      <button
        type='submit'
        className={`${
          lightMode
            ? 'bg-gray-800 hover:bg-black '
            : 'bg-red-800 hover:bg-red-600'
        }  text-white font-semibold text-lg p-2 mt-4 rounded w-5/6`}
      >
        Submit Feedback
      </button>
    </form>
  );
};

export default FeedbackForm;
