import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  pageState,
  lightModeState,
  isAccountModalState,
  isAddAccountOpenState,
  isUpdateFormOpenState,
  isDataFormOpenState,
} from '../../recoil/atoms';
import { Link } from 'react-router-dom';
import { FaChartPie } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';

type Props = {
  mobile?: boolean;
  handleClick?: () => void;
};

const NavOptions = ({ mobile, handleClick }: Props) => {
  const page = useRecoilValue(pageState);
  const lightMode = useRecoilValue(lightModeState);
  const isAccountModalOpen = useRecoilValue(isAccountModalState);
  const isAddAccountOpen = useRecoilValue(isAddAccountOpenState);
  const isUpdateFormOpen = useRecoilValue(isUpdateFormOpenState);
  const isDataFormOpen = useRecoilValue(isDataFormOpenState);

  const isAnyModalOpen =
    isAccountModalOpen ||
    isAddAccountOpen ||
    isUpdateFormOpen ||
    isDataFormOpen;

  return (
    <div
      className={`md:ml-auto md:mr-auto flex flex-col gap-y-3 flex-wrap items-start  justify-center ${
        mobile
          ? 'flex-col text-4xl mt-10 tracking-wider space-y-6 justify-center'
          : 'hidden sm:inline text-base flex-col'
      }`}
    >
      <Link
        className={`font-semibold mr-5 hover:no-underline flex items-center gap-x-4 rounded-lg ${
          page === 'home'
            ? lightMode
              ? 'text-green-400'
              : 'text-green-300'
            : lightMode
            ? 'text-black'
            : 'text-white'
        }`}
        to={isAnyModalOpen ? '#' : '/'}
        onClick={handleClick}
      >
        <AiFillHome
          className={`text-5xl ${
            page === 'home'
              ? ''
              : lightMode
              ? 'text-green-400'
              : 'text-green-300'
          }`}
        />{' '}
        Home
      </Link>
      <Link
        className={`font-semibold mr-5 hover:no-underline flex items-center gap-x-4 rounded-lg ${
          page === 'reports'
            ? lightMode
              ? 'text-green-400'
              : 'text-green-300'
            : lightMode
            ? 'text-black'
            : 'text-white'
        }`}
        to={isAnyModalOpen ? '#' : '/reports'}
        onClick={handleClick}
      >
        <FaChartPie
          className={`text-5xl ${
            page === 'reports'
              ? ''
              : lightMode
              ? 'text-green-400'
              : 'text-green-300'
          }`}
        />{' '}
        Reports
      </Link>
    </div>
  );
};
export default NavOptions;
