import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { type SetterOrUpdater, useRecoilValue } from 'recoil';
import { lightModeState } from '../../recoil/atoms';
import { RiArrowDownSFill } from 'react-icons/ri';

type Props = {
  label1: string;
  label2: string;
  label3: string;
  label4?: string;
  data1: string;
  data2: string;
  data3: string;
  data4?: string;
  color1: string;
  color2: string;
  color3: string;
  color4?: string;
  hovercolor1: string;
  hovercolor2: string;
  hovercolor3: string;
  hovercolor4?: string;
  title: string;
  isLabel?: boolean;
  isPrint?: boolean;
  setTitle?: SetterOrUpdater<string>;
};

const DoughnutGraph = ({
  label1,
  label2,
  label3,
  label4,
  data1,
  data2,
  data3,
  data4,
  color1,
  color2,
  color3,
  color4,
  hovercolor1,
  hovercolor2,
  hovercolor3,
  hovercolor4,
  title,
  // setTitle,
  isLabel,
  isPrint,
  setTitle,
}: Props) => {
  const data1Num = data1 ? parseFloat(data1) : 0;
  const data2Num = data2 ? parseFloat(data2) : 0;
  const data3Num = data3 ? parseFloat(data3) : 0;
  const data4Num = data4 ? parseFloat(data4) : 0;
  const lightMode = useRecoilValue(lightModeState);
  const dataArr = [data1Num, data2Num, data3Num];
  const colorArr = [color1, color2, color3];
  const hovercolorArr = [hovercolor1, hovercolor2, hovercolor3];

  const totalData = data1Num + data2Num + data3Num + data4Num;

  const convertToPercentage = (data: number) => {
    return ((data / totalData) * 100).toFixed(2);
  };

  const data1Percentage = convertToPercentage(data1Num);
  const data2Percentage = convertToPercentage(data2Num);
  const data3Percentage = convertToPercentage(data3Num);
  const data4Percentage = convertToPercentage(data4Num);

  const labelsArr = [
    `${label1} (${data1Percentage}%)`,
    `${label2} (${data2Percentage}%)`,
    `${label3} (${data3Percentage}%)`,
  ];

  if (data4 && data4Num > 0 && color4 && hovercolor4 && label4) {
    dataArr.push(data4Num);
    colorArr.push(color4);
    hovercolorArr.push(hovercolor4);
    labelsArr.push(`${label4} (${data4Percentage}%)`);
  }

  let data = {
    dataDoughnut: {
      labels: ['TBD'],
      datasets: [
        {
          data: [100],
          backgroundColor: ['#595260'],
          hoverBackgroundColor: ['#B2B1B9'],
          hoverOffset: 4,
        },
      ],
    },
  };

  const options = {
    responsive: true,
    events: isPrint
      ? ['']
      : ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    plugins: {
      legend: {
        labels: {
          color: lightMode || isPrint ? '#323232' : '#A6A9B6',
        },
      },
    },
  };

  if (
    (data1 && parseFloat(data1) > 0) ||
    (data2 && parseFloat(data2) > 0) ||
    (data3 && parseFloat(data3) > 0)
  ) {
    data = {
      dataDoughnut: {
        labels: labelsArr,
        datasets: [
          {
            data: dataArr,
            backgroundColor: colorArr,
            hoverBackgroundColor: hovercolorArr,
            hoverOffset: 4,
          },
        ],
      },
    };
  }
  return (
    <div
      className={`flex flex-col items-center mx-auto w-2/3 h-2/3 ${
        isLabel ? 'sm:w-4/12 sm:h-4/12' : 'sm:w-1/2 sm:h-1/2'
      }`}
    >
      {isLabel && (
        <div className='dropdown dropdown-hover dropdown-end z-10 mb-5 group'>
          <h3
            className={`text-lg sm:text-xl mt-5 text-center ${
              setTitle && !isPrint && 'cursor-pointer'
            } rounded ${
              lightMode || isPrint
                ? `text-gray-900 ${
                    setTitle &&
                    !isPrint &&
                    'group-hover:shadow group-hover:bg-gray-200'
                  }`
                : `text-white ${
                    setTitle &&
                    !isPrint &&
                    'group-hover:shadow group-hover:bg-green-800'
                  }`
            } flex px-2 py-1 items-center gap-x-2 `}
            tabIndex={0}
          >
            {title} {setTitle && !isPrint && <RiArrowDownSFill />}
          </h3>
          {setTitle && !isPrint && (
            <ul
              tabIndex={0}
              className={`dropdown-content border-t border-b border-l border-r menu w-44 ${
                lightMode
                  ? 'bg-gray-200 border-gray-400 text-gray-900'
                  : 'bg-green-800 border-green-500 text-white'
              } text-left menu rounded text-2xs sm:text-sm font-semibold uppercase w-full`}
            >
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('Leads') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Leads')}
              >
                Leads
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('Budget') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Budget')}
              >
                Budget
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300 ' : 'hover:bg-green-700'
                } ${
                  title.includes('Impressions') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Impressions')}
              >
                Impressions
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('Clicks') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Clicks')}
              >
                Clicks
              </li>
            </ul>
          )}
        </div>
      )}
      <Doughnut type='doughnut' data={data.dataDoughnut} options={options} />
    </div>
  );
};

export default DoughnutGraph;
