import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { pageState, lightModeState } from '../recoil/atoms';
import SignInComponent from '../components/forms/non-modal/SignInComponent';
import Layout from '../components/layout/Layout';

const SignIn = () => {
  const setPage = useSetRecoilState(pageState);
  const lightMode = useRecoilValue(lightModeState);

  useEffect(() => {
    window.scrollTo(0, 0);
    setPage('sign-in');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className='w-full h-full md:grid  md:grid-cols-3'>
        <div className={lightMode ? 'bg-gray-100' : 'bg-green-800'}></div>
        <SignInComponent />
        <div className={lightMode ? 'bg-gray-100' : 'bg-green-900'}></div>
      </div>
    </Layout>
  );
};
//
export default SignIn;
