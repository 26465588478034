import React from 'react';
import { useRecoilValue } from 'recoil';
import { lightModeState } from '../../recoil/atoms';
import { Commaed } from '../../helpers/utils';

type Props = {
  google: string;
  microsoft: string;
  lsa: string;
  manual?: string;
};

const EachReportsPlatform = ({ google, microsoft, lsa, manual }: Props) => {
  const lightMode = useRecoilValue(lightModeState);

  return (
    <div
      className={`w-full h-7/12 rounded-b-md grid border-t ${
        manual ? 'grid-cols-4' : 'grid-cols-3'
      } ${
        lightMode
          ? 'bg-gray-200 border-gray-200'
          : 'bg-green-800 border-green-800'
      }`}
    >
      <div className='py-3'>
        <p className='text-base font-semibold'>
          {Commaed(parseFloat(google).toFixed(2))}
        </p>
        <span className='text-xs'>Google</span>
      </div>
      <div className={`py-3 ${lightMode ? 'bg-gray-300' : 'bg-green-700'}`}>
        <p className='text-base font-semibold'>
          {Commaed(parseFloat(microsoft).toFixed(2))}
        </p>
        <span className='text-xs'>Microsoft</span>
      </div>
      <div className='py-3'>
        <p className='text-base font-semibold'>
          {Commaed(parseFloat(lsa).toFixed(2))}
        </p>
        <span className='text-xs'>LSA</span>
      </div>
      {manual && (
        <div className={`py-3 ${lightMode ? 'bg-gray-200' : 'bg-green-700'}`}>
          <p className='text-base font-semibold'>
            {Commaed(parseFloat(manual).toFixed(2))}
          </p>
          <span className='text-xs'>Manual Added</span>
        </div>
      )}
    </div>
  );
};

export default EachReportsPlatform;
