import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { CheckboxOptions } from '../../helpers/types';

const { persistAtom } = recoilPersist();

export const pageState = atom<
  'home' | 'data' | 'password' | 'reports' | 'sign-in' | 'adduser' | 'feedback'
>({
  key: 'pageState',
  default: 'home',
});

export const lightModeState = atom<boolean>({
  key: 'lightModeState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const isLoadingSearchTermState = atom({
  key: 'isLoadingSearchTermState',
  default: false,
});

export const isLoadingUserLocationState = atom({
  key: 'isLoadingUserLocationState',
  default: false,
});

export const monNameState = atom<
  | '90-Day'
  | '30-Day'
  | '3-Day'
  | 'Yesterday'
  | 'This Mo'
  | 'All Time'
  | 'Jan'
  | 'Feb'
  | 'Mar'
  | 'Apr'
  | 'May'
  | 'Jun'
  | 'Jul'
  | 'Aug'
  | 'Sept'
  | 'Oct'
  | 'Nov'
  | 'Dec'
>({
  key: 'monNameState',
  default: '30-Day',
});

export const isLoadingDataState = atom({
  key: 'isLoadingDataState',
  default: false,
});

export const isLoadingAdGroupState = atom({
  key: 'isLoadingAdGroupState',
  default: false,
});

export const isMenuOpenState = atom({
  key: 'isMenuOpenState',
  default: false,
});

export const isUpdateFormOpenState = atom({
  key: 'isUpdateFormOpenState',
  default: false,
});

export const isAccountModalState = atom({
  key: 'isAccountModalState',
  default: false,
});

export const isAddAccountOpenState = atom({
  key: 'isAddAccountOpenState',
  default: false,
});

export const isChangeAttFormOpenState = atom({
  key: 'isChangeNameFormOpenState',
  default: false,
});

export const searchTermFilterState = atom<'Google' | 'Microsoft'>({
  key: 'searchTermFilterState',
  default: 'Google',
});

export const deviceChartFilterState = atom<'Google' | 'Microsoft'>({
  key: 'deviceChartFilterState',
  default: 'Google',
});

export const isLineDataLoadingState = atom({
  key: 'isLineDataLoadingState',
  default: false,
});

export const isDataFormOpenState = atom({
  key: 'isDataFormOpenState',
  default: false,
});

export const dataPageFormCompState = atom<'AddData' | 'ChangeAtt' | 'Note'>({
  key: 'dataPageFormNavState',
  default: 'AddData',
});

export const budgetComparatorState = atom<'=' | '>' | '<' | '>=' | '<='>({
  key: 'budgetFilterComparatorState',
  default: '=',
});

export const costComparatorState = atom<'=' | '>' | '<' | '>=' | '<='>({
  key: 'costFilterComparatorState',
  default: '=',
});

export const remainingBudgetComparatorState = atom<
  '=' | '>' | '<' | '>=' | '<='
>({
  key: 'remainingBudgeComparatorState',
  default: '=',
});

export const paceComparatorState = atom<'=' | '>' | '<' | '>=' | '<='>({
  key: 'paceComparatorState',
  default: '=',
});

export const cplComparatorState = atom<'=' | '>' | '<' | '>=' | '<='>({
  key: 'cplComparatorState',
  default: '=',
});

export const convComparatorState = atom<'=' | '>' | '<' | '>=' | '<='>({
  key: 'convComparatorState',
  default: '=',
});

export const budgetFilterState = atom({
  key: 'budgetFIlterNumberState',
  default: '',
});

export const costFilterState = atom({
  key: 'costFilterNumberState',
  default: '',
});

export const remainingBudgetFilterState = atom({
  key: 'remainingBudgeFilterState',
  default: '',
});

export const paceFilterState = atom({
  key: 'paceFilterState',
  default: '',
});

export const cplFilterState = atom({
  key: 'cplFilterState',
  default: '',
});

export const convFilterState = atom({
  key: 'convFilterState',
  default: '',
});

export const dataCoverageState = atom<
  | '90-Day'
  | '30-Day'
  | '3-Day'
  | 'Yesterday'
  | 'This Mo'
  | 'All Time'
  | 'Jan'
  | 'Feb'
  | 'Mar'
  | 'Apr'
  | 'May'
  | 'Jun'
  | 'Jul'
  | 'Aug'
  | 'Sept'
  | 'Oct'
  | 'Nov'
  | 'Dec'
>({
  key: 'dataCoverageState',
  default: 'This Mo',
});

export const showFilterModalState = atom({
  key: 'showFilterModalState',
  default: false,
});

export const userLocationFilterState = atom<'Google' | 'Microsoft'>({
  key: 'userLocationFilterState',
  default: 'Google',
});

export const overviewUserLocationState = atom<string>({
  key: 'overviewUserLocationState',
  default: 'Most Converting',
  effects_UNSTABLE: [persistAtom],
});

export const overviewPrimitiveDataState = atom<string>({
  key: 'overviewPrimitiveDataState',
  default: 'Total Conversions',
  effects_UNSTABLE: [persistAtom],
});

export const overviewKeywordState = atom<string>({
  key: 'overviewKeywordState',
  default: 'Most Converting',
  effects_UNSTABLE: [persistAtom],
});

export const overviewFirstDoughnutChartState = atom<string>({
  key: 'overviewFirstDoughnutChartState',
  default: 'Leads Per Platform',
  effects_UNSTABLE: [persistAtom],
});

export const overviewSecondDoughnutChartState = atom<string>({
  key: 'overviewSecondDoughnutChartState',
  default: 'Leads Per Device (Google)',
  effects_UNSTABLE: [persistAtom],
});

export const overviewLineGraphState = atom<string>({
  key: 'overviewLineGraphState',
  default: 'Leads Per Coverage',
  effects_UNSTABLE: [persistAtom],
});

export const reportBoxOneState = atom<string>({
  key: 'reportBoxOneState',
  default: 'Cost Per Lead',
  effects_UNSTABLE: [persistAtom],
});

export const reportBoxTwoState = atom<string>({
  key: 'reportBoxTwoState',
  default: 'Ad Investment',
  effects_UNSTABLE: [persistAtom],
});

export const reportBoxThreeState = atom<string>({
  key: 'reportBoxThreeState',
  default: 'Conversions',
  effects_UNSTABLE: [persistAtom],
});

export const firstPlatformChartState = atom<string>({
  key: 'firstPlatformChartState',
  default: 'Leads',
  effects_UNSTABLE: [persistAtom],
});

export const secondPlatformChartState = atom<string>({
  key: 'secondPlatformChartState',
  default: 'Budget',
  effects_UNSTABLE: [persistAtom],
});

export const firstCoverageGraphState = atom<string>({
  key: 'firstCoverageGraphState',
  default: 'Leads',
  effects_UNSTABLE: [persistAtom],
});

export const secondCoverageGraphState = atom<string>({
  key: 'secondCoverageGraphState',
  default: 'Budget',
  effects_UNSTABLE: [persistAtom],
});

export const isEditingColumsState = atom({
  key: 'isEditingColumsState',
  default: false,
});

export const column2State = atom<string>({
  key: 'column2State',
  default: 'Budget',
  effects_UNSTABLE: [persistAtom],
});

export const column3State = atom<string>({
  key: 'column3State',
  default: 'Cost',
  effects_UNSTABLE: [persistAtom],
});

export const column4State = atom<string>({
  key: 'column4State',
  default: 'Remaining Budget',
  effects_UNSTABLE: [persistAtom],
});

export const column5State = atom<string>({
  key: 'column5State',
  default: 'Pace',
  effects_UNSTABLE: [persistAtom],
});

export const column6State = atom<string>({
  key: 'column6State',
  default: 'CPL',
  effects_UNSTABLE: [persistAtom],
});

export const column7State = atom<string>({
  key: 'column7State',
  default: 'Conv.',
  effects_UNSTABLE: [persistAtom],
});

export const column8State = atom<string>({
  key: 'column8State',
  default: 'Ads Paid By',
  effects_UNSTABLE: [persistAtom],
});

export const column9State = atom<string>({
  key: 'column9State',
  default: 'State',
  effects_UNSTABLE: [persistAtom],
});

export const column10State = atom<string>({
  key: 'column10State',
  default: 'Account Manager',
  effects_UNSTABLE: [persistAtom],
});

export const column11State = atom<string>({
  key: 'column11State',
  default: 'PPC Manger',
  effects_UNSTABLE: [persistAtom],
});

export const column12State = atom<string>({
  key: 'column12State',
  default: 'IDX',
  effects_UNSTABLE: [persistAtom],
});

export const thisMoActiveColumnsState = atom<string[]>({
  key: 'thisMoActiveColumnsState',
  default: [
    'Email',
    'Budget',
    'Cost',
    'Remaining Budget',
    'Pace',
    'CPL',
    'Conv.',
    'Ads Paid By',
    'State',
    'Account Manager',
    'PPC Manager',
    'IDX',
  ],
  effects_UNSTABLE: [persistAtom],
});

export const thisMoInactiveColumnState = atom<string[]>({
  key: 'thisMoInactiveColumnState',
  default: [
    'Impressions',
    'Clicks',
    'CTR',
    'Search Lost IS Rank (Google)',
    'Search Lost IS Budget (Google)',
    'Search Lost IS Rank (Microsoft)',
    'Search Lost IS Budget (Microsoft)',
  ],
  effects_UNSTABLE: [persistAtom],
});

export const activeColumnState = atom<string[]>({
  key: 'activeColumnsState',
  default: [
    'Email',
    'Budget',
    'Cost',
    'CPL',
    'Conv.',
    'State',
    'Account Manager',
    'PPC Manager',
    'IDX',
  ],
  effects_UNSTABLE: [persistAtom],
});

export const inactiveColumnState = atom<string[]>({
  key: 'inactiveColumnState',
  default: [
    'Impressions',
    'Clicks',
    'CTR',
    'Search Lost IS Rank (Google)',
    'Search Lost IS Budget (Google)',
    'Search Lost IS Rank (Microsoft)',
    'Search Lost IS Budget (Microsoft)',
  ],
  effects_UNSTABLE: [persistAtom],
});

export const isOpenProfModalState = atom({
  key: 'isOpenProfModalState',
  default: false,
});

export const platformChartsPrintState = atom<CheckboxOptions>({
  key: 'platformChartsPrintState',
  default: {
    'First Chart': true,
    'Second Chart': true,
  },
});

export const deviceChartsPrintState = atom<CheckboxOptions>({
  key: 'deviceChartsPrintState',
  default: {
    'Leads Per Device (Google)': false,
    'Budget Per Device (Google)': false,
    'Leads Per Device (Microsoft)': false,
    'Budget Per Device (Microsoft)': false,
  },
});

export const coverageGraphPrintState = atom<CheckboxOptions>({
  key: 'coverageGraphPrintState',
  default: {
    'First Graph': true,
    'Second Graph': true,
  },
});

export const remarketingPrintState = atom<CheckboxOptions>({
  key: 'remarketingPrintState',
  default: {
    Remarketing: false,
  },
});

export const ulPrintComponentState = atom<string>({
  key: 'ulPrintComponentState',
  default: 'Most Converting',
  effects_UNSTABLE: [persistAtom],
});

export const kwPrintComponentState = atom<string>({
  key: 'kwPrintComponentState',
  default: 'Most Converting',
  effects_UNSTABLE: [persistAtom],
});

export const searchState = atom({
  key: 'searchState',
  default: '',
});

export const isOpenNotifBoxState = atom({
  key: 'isOpenNotifBoxState',
  default: false,
});

export const isOpenNotifPopupState = atom({
  key: 'isOpenNotifPopupState',
  default: true,
});
