import React, { useState } from 'react';
import { lightModeState, isLoadingDataState } from '../../../recoil/atoms';
import { auth2, firestore } from '../../../firebase/firebase';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import swal from 'sweetalert';
import { adminAccountState } from '../../../recoil/selectors/dataSelectors';

const AddUserComponent = () => {
  const lightMode = useRecoilValue(lightModeState);
  const account = useRecoilValue(adminAccountState);
  const setIsLoading = useSetRecoilState(isLoadingDataState);
  const [email, setEmail] = useState('');

  const addUser = async () => {
    setIsLoading(true);
    await auth2
      .createUserWithEmailAndPassword(email, 'Asterisk2022!')
      .then(
        async ({ user }) =>
          await firestore
            .collection('users')
            .doc(user?.uid)
            .set({
              displayName: account.name,
              email: email,
              googleCID: account.google_cid,
              microsoftCID: account.microsoft_cid,
              role: 'admin',
            })
            .then(() => {
              swal(
                'Succefully Changed Password',
                'Admin User Has Been Added',
                'success',
                {
                  timer: 1000,
                  buttons: [false],
                }
              );
              setIsLoading(false);
            })
            .catch((err) => {
              swal('Unable to add user', err.message, 'error');
              setIsLoading(false);
            })
      )
      .catch((err) => {
        setIsLoading(false);
        swal('Unable to add user', err.message, 'error');
      });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addUser();
  };

  return (
    <div
      className={`w-screen sm:w-full flex flex-col ${
        lightMode ? 'bg-gray-100' : 'bg-green-900'
      } h-full`}
    >
      <div className='my-auto md:pt-0'>
        <form className='mx-4 sm:mx-0 flex flex-col' onSubmit={handleSubmit}>
          <div className='flex flex-col pt-4'>
            <input
              type='email'
              id='email'
              placeholder='admin@email.com'
              name='email'
              className='text-center appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none font-semibold w-full border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
              onChange={handleChange}
              value={email}
              autoComplete='off'
            />
          </div>
          <button
            type='submit'
            className={`${
              lightMode
                ? 'bg-gray-900 hover:bg-black '
                : 'bg-red-800 hover:bg-red-600'
            }  text-white font-semibold text-lg p-2 mt-9 rounded w-full`}
          >
            Add User
          </button>
        </form>
        <div className='text-center pt-12 pb-12'></div>
      </div>
    </div>
  );
};

export default AddUserComponent;
