import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  isUpdateFormOpenState,
  isAddAccountOpenState,
  pageState,
  isAccountModalState,
  isLoadingDataState,
  userState,
  accountState,
} from '../recoil/atoms';
import Title from '../components/universal-decorations/Title';
import Layout from '../components/layout/Layout';
import OverviewComponent from '../components/parent-comps/OverviewComponent';
import SelectAccountButton from '../components/buttons/SelectAccountButton';
import SelectAccountModal from '../components/forms/modal/SelectAccountModal';
import Coverage from '../components/date-related/Coverage';
import LoadAnimation from '../components/universal-decorations/LoadAnimation';
import UpdateAccountForm from '../components/forms/modal/UpdateAccountForm';
import AddAccountForm from '../components/forms/modal/AddAccountForm';

const Home = () => {
  const setPage = useSetRecoilState(pageState);
  const showModal = useRecoilValue(isAccountModalState);
  const isUpdateFormOpen = useRecoilValue(isUpdateFormOpenState);
  const isAddAccountOpen = useRecoilValue(isAddAccountOpenState);
  const account = useRecoilValue(accountState);
  const isLoadingData = useRecoilValue(isLoadingDataState);
  const user = useRecoilValue(userState);
  useEffect(() => {
    setPage('home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div>
        <div className=' grid grid-cols-3 justify-items-center content-center'>
          <div className='my-auto sm:ml-56 w-full'>
            <Coverage />
          </div>
          <Title
            title={
              user?.role !== 'superadmin'
                ? user
                  ? user.displayName
                  : ''
                : account.name === 'None'
                ? 'Easy Agent PRO'
                : account.name
            }
          />
          {user?.role === 'superadmin' ? <SelectAccountButton /> : <div></div>}
        </div>
        {isLoadingData ? (
          <div className='mt-32'>
            <LoadAnimation />
          </div>
        ) : (
          <div>
            <OverviewComponent />
          </div>
        )}

        {showModal && <SelectAccountModal />}
        {isUpdateFormOpen && <UpdateAccountForm />}
        {isAddAccountOpen && <AddAccountForm />}
      </div>
    </Layout>
  );
};

export default Home;
