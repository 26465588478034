import React from 'react';
import { lightModeState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { SearchTerm, UserLocation } from '../../helpers/types';

type Props = {
  showFilter: boolean;
  handleSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filter: 'Google' | 'Microsoft';
  isSearchTerm?: boolean;
  searchTerm?: UserLocation[] | SearchTerm[];
  bingSearchTerm?: UserLocation[] | SearchTerm[];
  name: string;
};

const ReportsCompFilterDropdown = ({
  showFilter,
  handleSelect,
  filter,
  isSearchTerm,
  searchTerm,
  bingSearchTerm,
  name,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const env = process.env.NODE_ENV;
  return (
    <div
      className={`overflow-y-scroll absolute rounded mt-0.5  z-20 ${
        lightMode
          ? 'bg-gray-200 text-black shadow-sm'
          : 'bg-green-800 text-white shadow'
      } w-1/2 ${isSearchTerm ? 'sm:w-1/4' : 'sm:w-1/6'} ${
        env === 'production' ? 'py-2' : 'pt-2'
      } pl-2 -mr-2 ${!showFilter && 'hidden'}`}
    >
      <ul>
        <li className='pt-2'>
          <label className='cursor-pointer px-2 form-radio-label'>
            <input
              type='radio'
              className='form-radio-input'
              value='Google'
              name={name}
              checked={filter === 'Google'}
              onChange={handleSelect}
              disabled={isSearchTerm ? searchTerm?.length === 0 : false}
            />
            <span className=' ml-3 text-2xs sm:text-base '>Google</span>
          </label>
        </li>
        <li className='pt-2'>
          <label className='cursor-pointer px-2 form-radio-label'>
            <input
              type='radio'
              className='form-radio-input'
              value='Microsoft'
              checked={filter === 'Microsoft'}
              name={name}
              onChange={handleSelect}
              disabled={isSearchTerm ? bingSearchTerm?.length === 0 : false}
            />
            <span className='ml-3 text-2xs sm:text-base'>Microsoft</span>
          </label>
        </li>
      </ul>
    </div>
  );
};

export default ReportsCompFilterDropdown;
