import React from 'react';

type Props = {
  children: React.ReactNode;
  handleClose: () => void;
};

const ModalBase = ({ children, handleClose }: Props) => {
  return (
    <div
      className='fixed z-50 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        ></div>
        <div className='inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full h-1/2'>
          <div className='bg-white rounded-lg p-4 flex flex-col md:ml-auto mx-auto w-full sm:w-3/4 mt-48'>
            <div className='flex justify-end w-full'>
              <button
                type='button'
                className='bg-white hover:bg-gray-100 rounded-md p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                onClick={handleClose}
              >
                <span className='sr-only'>Close menu</span>
                <svg
                  className='h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBase;
