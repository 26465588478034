import React from 'react';
import { lightModeState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';

type Props = {
  showFilter: boolean;
  handleSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filter: string;
};

const DeviceGraphsFilter = ({ showFilter, handleSelect, filter }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const env = process.env.NODE_ENV;
  return (
    <div
      className={`overflow-y-scroll absolute rounded mt-0.5  z-20 ${
        lightMode
          ? 'bg-gray-200 text-black shadow-sm'
          : 'bg-green-800 text-white shadow'
      } w-1/2 sm:w-1/5 ${env === 'production' ? 'py-2' : 'pt-2'} pl-2 -mr-2 ${
        !showFilter && 'hidden'
      }`}
    >
      <ul>
        <li className='pt-2'>
          <label className='cursor-pointer px-2 form-radio-label'>
            <input
              type='radio'
              className='form-radio-input'
              value='Mobile'
              name='device-line-graphs'
              checked={filter === 'Mobile'}
              onChange={handleSelect}
            />
            <span className=' ml-3 text-2xs sm:text-base '>Mobile</span>
          </label>
        </li>
        <li className='pt-2'>
          <label className='cursor-pointer px-2 form-radio-label'>
            <input
              type='radio'
              className='form-radio-input'
              value='Desktop'
              checked={filter === 'Desktop'}
              name='device-line-graphs'
              onChange={handleSelect}
            />
            <span className='ml-3 text-2xs sm:text-base'>Desktop</span>
          </label>
        </li>
        <li className='pt-2'>
          <label className='cursor-pointer px-2 form-radio-label'>
            <input
              type='radio'
              className='form-radio-input'
              value='Tablet'
              checked={filter === 'Tablet'}
              name='device-line-graphs'
              onChange={handleSelect}
            />
            <span className='ml-3 text-2xs sm:text-base'>Tablet</span>
          </label>
        </li>
      </ul>
    </div>
  );
};

export default DeviceGraphsFilter;
