import React from 'react';
import { useRecoilValue } from 'recoil';
import { SearchTerm } from '../../helpers/types';
import { lightModeState } from '../../recoil/atoms';

type Props = {
  item: SearchTerm;
};

const SearchTermTableData = ({ item }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  return (
    <tr
      className={`${
        lightMode ? 'text-gray-600' : 'text-gray-400'
      } text-2xs sm:text-sm`}
    >
      <td
        className={`${
          lightMode ? 'text-black' : 'text-white'
        } border-t-2 border-green-700 pr-5 pl-4 py-3`}
      >
        {item.name}
      </td>
      <td className='border-t-2 border-green-700 px-4 py-3'>
        {item.impressions}
      </td>
      <td className='border-t-2 border-green-700 px-4 py-3'>{item.clicks}</td>
      <td className='border-t-2 border-green-700 px-4 py-3'>{item.ctr}%</td>
      <td className='border-t-2 border-green-700 px-4 py-3'>${item.avg_cpc}</td>
      <td className='border-t-2 border-green-700 px-4 py-3'>${item.spend}</td>
      <td className='border-t-2 border-green-700 px-4 py-3'>
        {item.conversions}
      </td>
      <td className='border-t-2 border-green-700 px-4 py-3'>
        ${item.cost_per_conversion}
      </td>
    </tr>
  );
};

export default SearchTermTableData;
