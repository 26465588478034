import { AiFillHome, AiTwotoneAppstore } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BiPlus, BiSun, BiMoon } from 'react-icons/bi';
import { BsPersonCircle } from 'react-icons/bs';
import { FaChartPie } from 'react-icons/fa';
import {
  pageState,
  lightModeState,
  isOpenProfModalState,
  isAuthenticatedState,
  isMenuOpenState,
} from '../../recoil/atoms';
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  type SetterOrUpdater,
} from 'recoil';
import { GiHamburgerMenu } from 'react-icons/gi';

const Navbar = () => {
  const page = useRecoilValue(pageState);
  const [lightMode, setLightMode] = useRecoilState(lightModeState);
  const [isOpenProfModal, setIsOpenProfModal] =
    useRecoilState(isOpenProfModalState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState);
  const toggleModal = (
    isOpen: boolean,
    setIsOpenPrimary: SetterOrUpdater<boolean>
  ) => {
    setIsOpenPrimary(!isOpen);
  };
  return (
    <div
      className={`sm:min-h-screen fixed left-0 flex sm:flex-col justify-between items-center ${
        lightMode ? 'bg-gray-100 sm:bg-green-500 ' : 'bg-green-900 sm:bg-white '
      } py-3 px-3 z-50 navb`}
    >
      <div
        className={`text-3xl rounded-full p-2.5 ${
          lightMode ? 'bg-green-500 sm:bg-white' : 'bg-gray-50 sm:bg-green-500'
        }`}
      >
        <Link to='/'>
          <AiTwotoneAppstore
            className={`${
              lightMode
                ? 'text-gray-50 sm:text-green-500'
                : 'text-green-500 sm:text-white'
            }`}
          />
        </Link>
      </div>
      <div
        className={`${
          isAuthenticated ? 'hidden sm:flex' : 'hidden'
        } flex-col justify-between items-center gap-y-6 text-2xl `}
      >
        <Link
          to='/'
          className={`text-center group ${
            lightMode ? 'hover:bg-white' : 'hover:bg-green-500'
          } p-2 transition duration-300 rounded-sm ${
            page === 'home' ? (lightMode ? 'bg-white' : 'bg-green-500') : ''
          }`}
        >
          <AiFillHome
            className={`${
              lightMode
                ? 'group-hover:text-green-500'
                : 'group-hover:text-white'
            } ${
              page === 'home'
                ? lightMode
                  ? 'text-green-500'
                  : 'text-white'
                : lightMode
                ? 'text-gray-50'
                : 'text-green-500'
            } transition duration-300`}
          />
        </Link>
        <Link
          to='/reports'
          className={`text-center group ${
            lightMode ? 'hover:bg-white' : 'hover:bg-green-500'
          } p-2 transition duration-300 rounded-sm ${
            page === 'reports' ? (lightMode ? 'bg-white' : 'bg-green-500') : ''
          }`}
        >
          <FaChartPie
            className={`${
              lightMode
                ? 'group-hover:text-green-500'
                : 'group-hover:text-white'
            } ${
              page === 'reports'
                ? lightMode
                  ? 'text-green-500'
                  : 'text-white'
                : lightMode
                ? 'text-gray-50'
                : 'text-green-500'
            } transition duration-300`}
          />
        </Link>
      </div>
      <div className='sm:flex hidden flex-col items-center'>
        {lightMode ? (
          <BiMoon
            className={`${
              lightMode ? 'text-white' : 'text-green-700'
            } cursor-pointer ${isAuthenticated ? 'mb-2' : ''}`}
            onClick={() => setLightMode(false)}
            title='Dark Mode'
          />
        ) : (
          <BiSun
            className={`${
              lightMode ? 'text-white' : 'text-green-700'
            } cursor-pointer ${isAuthenticated ? 'mb-2' : ''}`}
            onClick={() => setLightMode(true)}
            title='Light Mode'
          />
        )}
        <Link
          to='/adduser'
          className={`text-center p-2 ${isAuthenticated ? '' : 'hidden'}`}
        >
          <BiPlus
            className={`${lightMode ? 'text-white' : 'text-green-700'}`}
          />
        </Link>
        <div
          className={`p-2.5 rounded ${
            isOpenProfModal ? (lightMode ? 'bg-green-400' : 'bg-gray-200') : ''
          } ${isAuthenticated ? '' : 'hidden'}`}
        >
          <BsPersonCircle
            className={`${
              lightMode ? 'text-white' : 'text-green-700'
            } cursor-pointer
            `}
            onClick={() => toggleModal(isOpenProfModal, setIsOpenProfModal)}
          />
        </div>
      </div>
      {isAuthenticated ? (
        <div
          className={`sm:hidden ${
            lightMode ? 'text-gray-700' : 'text-gray-50'
          } text-3xl`}
          onClick={() => setIsMenuOpen(true)}
        >
          <GiHamburgerMenu />
        </div>
      ) : lightMode ? (
        <BiMoon
          className={`${
            lightMode ? 'text-white' : 'text-gray-300'
          } cursor-pointer ${isAuthenticated ? 'mb-2' : ''} sm:hidden`}
          onClick={() => setLightMode(false)}
          title='Dark Mode'
        />
      ) : (
        <BiSun
          className={`${
            lightMode ? 'text-white' : 'text-gray-300'
          } cursor-pointer ${isAuthenticated ? 'mb-2' : ''} sm:hidden`}
          onClick={() => setLightMode(true)}
          title='Light Mode'
        />
      )}
    </div>
  );
};

export default Navbar;
