import React, { useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  isAuthenticatedState,
  jwtState,
  userState,
  lightModeState,
  firebaseUserState,
} from '../../../recoil/atoms';
import { signIn, snapshot } from '../../../helpers/auth';
import swal from 'sweetalert';
import { AiTwotoneAppstore } from 'react-icons/ai';

const SignInComponent = () => {
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedState);
  const setFirebaseUser = useSetRecoilState(firebaseUserState);

  const [showPassword, setShowPassword] = useState(false);
  const [loginCred, setLoginCred] = useState({
    email: '',
    password: '',
  });
  const setUser = useSetRecoilState(userState);
  const setJWT = useSetRecoilState(jwtState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginCred({ ...loginCred, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await signIn(loginCred.email, loginCred.password)
      .then(async (user) => {
        const userRef = user.user ? await snapshot(user.user) : undefined;
        const token = await user.user?.getIdToken();
        setFirebaseUser(user.user);
        if (token) setJWT(token);
        setUser({
          displayName: userRef?.displayName,
          email: userRef?.email,
          googleCID: userRef?.googleCID,
          microsoftCID: userRef?.microsoftCID,
          role: userRef?.role,
          isPPCManager: userRef?.isPPCManager,
        });
        setIsAuthenticated(true);
        swal('Login Success!', `Welcome ${userRef?.displayName}!`, 'success', {
          timer: 1000,
          buttons: [false],
        });
      })
      .catch((err) => {
        swal(
          "Wasn't able to login",
          err.message.includes('signIn')
            ? 'Wrong Email Or Password'
            : err.message,
          'error'
        );
        console.log(err);
        setLoginCred({
          email: '',
          password: '',
        });
      });
  };

  const lightMode = useRecoilValue(lightModeState);
  return (
    <div
      className={`w-screen sm:w-full flex flex-col ${
        lightMode
          ? 'bg-gray-100'
          : 'bg-gradient-to-r from-green-800 to-green-900'
      } h-full`}
    >
      <div className='my-auto md:pt-0'>
        <div
          className={`text-6xl rounded-full p-4 mx-auto w-min mt-5 bg-green-500`}
        >
          <AiTwotoneAppstore className={`text-white`} />
        </div>
        <form className='mx-4 sm:mx-0 flex flex-col' onSubmit={handleSubmit}>
          <div className='flex flex-col pt-4'>
            <label
              htmlFor='email'
              className={`text-lg ${
                lightMode ? 'text-gray-900' : 'text-white'
              }`}
            >
              Email
            </label>

            <input
              type='email'
              id='email'
              placeholder='your@email.com'
              onChange={handleChange}
              name='email'
              value={loginCred.email}
              className={`${
                lightMode
                  ? 'border-l border-r border-t border-b border-gray-500'
                  : 'shadow'
              } appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full`}
            />
          </div>

          <div className='flex flex-col pt-4'>
            <label
              htmlFor='password'
              className={`text-lg ${
                lightMode ? 'text-gray-900' : 'text-white'
              }`}
            >
              Password
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              placeholder='Password'
              onChange={handleChange}
              name='password'
              value={loginCred.password}
              className={`${
                lightMode
                  ? 'border-l border-r border-t border-b border-gray-500'
                  : 'shadow'
              } appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full`}
            />
          </div>
          <label className='text-sm text-white mt-3 cursor-pointer'>
            <input
              type='checkbox'
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              className='cursor-pointer rounded  border-l border-r border-t border-b border-gray-700'
            />
            <span
              className={`ml-2.5 text-2xs sm:text-base ${
                lightMode ? 'text-gray-900' : 'text-gray-200'
              }`}
            >
              Show Password
            </span>
          </label>

          <button
            type='submit'
            className={`${
              lightMode
                ? 'hover:bg-black bg-gray-900 '
                : 'bg-gray-900 hover:bg-black'
            }  text-white font-bold text-lg p-2 mt-9 rounded w-full`}
          >
            Log In
          </button>
        </form>
        <div className='text-center pt-12 pb-12'></div>
      </div>
    </div>
  );
};

export default SignInComponent;
