import React from 'react';
import swal from 'sweetalert';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { signOut } from '../../helpers/auth';
import {
  isAuthenticatedState,
  jwtState,
  userState,
  lightModeState,
  isLoadingSearchTermState,
  isLoadingDataState,
  monNameState,
  accountsState,
  accountState,
  googleDataSelectedState,
  lsaReportsSelectedState,
  microsoftDataSelectedState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  searchTerm30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  userLocation30DaysAgoState,
  bingUserLocation30DaysAgoState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  accountsYesterdayState,
  accounts3DaysAgoState,
  accounts90DaysAgoState,
  accountsAprState,
  accountsAugState,
  accountsDecState,
  accountsFebState,
  accountsJanState,
  accountsJulState,
  accountsJunState,
  accountsMarState,
  accountsMayState,
  accountsNovState,
  accountsOctState,
  accountsSeptState,
  searchTermAprState,
  searchTermAugState,
  searchTermDecState,
  searchTermFebState,
  searchTermJanState,
  searchTermJulState,
  searchTermJunState,
  searchTermMarState,
  searchTermMayState,
  searchTermNovState,
  searchTermOctState,
  searchTermSeptState,
  bingSearchTermAprState,
  bingSearchTermAugState,
  bingSearchTermDecState,
  bingSearchTermFebState,
  bingSearchTermJanState,
  bingSearchTermJulState,
  bingSearchTermJunState,
  bingSearchTermMarState,
  bingSearchTermMayState,
  bingSearchTermNovState,
  bingSearchTermOctState,
  bingSearchTermSeptState,
  userLocationAprState,
  userLocationAugState,
  userLocationDecState,
  userLocationFebState,
  userLocationJanState,
  userLocationJulState,
  userLocationJunState,
  userLocationMarState,
  userLocationMayState,
  userLocationNovState,
  userLocationOctState,
  userLocationSeptState,
  bingUserLocationAprState,
  bingUserLocationAugState,
  bingUserLocationDecState,
  bingUserLocationFebState,
  bingUserLocationJanState,
  bingUserLocationJulState,
  bingUserLocationJunState,
  bingUserLocationMarState,
  bingUserLocationMayState,
  bingUserLocationNovState,
  bingUserLocationOctState,
  bingUserLocationSeptState,
  thisMonthAccountsState,
  selectedAccountState,
  searchTermFilterState,
  accountManagersFilterState,
  accManagerOptionState,
  ppcSpecialistsFilterState,
  ppcFilterOptionState,
  idxPlatformsFilterState,
  idxFilterOptionsState,
  statesFilterState,
  statesOptionState,
  isAccountModalState,
  isAddAccountOpenState,
  isUpdateFormOpenState,
  isDataFormOpenState,
  userLocationFilterState,
  deviceChartFilterState,
  dataCoverageState,
  overviewUserLocationState,
  overviewPrimitiveDataState,
  overviewKeywordState,
  overviewFirstDoughnutChartState,
  overviewSecondDoughnutChartState,
  overviewLineGraphState,
  reportBoxOneState,
  reportBoxTwoState,
  reportBoxThreeState,
  firstPlatformChartState,
  secondPlatformChartState,
  platformFilterState,
  platformOptionState,
  isEditingColumsState,
  column2State,
  column3State,
  column4State,
  column5State,
  column6State,
  column7State,
  column8State,
  column9State,
  column10State,
  column11State,
  column12State,
  thisMoActiveColumnsState,
  thisMoInactiveColumnState,
  activeColumnState,
  inactiveColumnState,
  isOpenProfModalState,
  platformChartsPrintState,
  deviceChartsPrintState,
  coverageGraphPrintState,
  remarketingPrintState,
  isOpenNotifBoxState,
  isOpenNotifPopupState,
  searchState,
} from '../../recoil/atoms';

type Props = {
  handleClose?: () => void;
  mobile?: boolean;
};

const LogOutButton = ({ handleClose, mobile }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const isAccountModalOpen = useRecoilValue(isAccountModalState);
  const isAddAccountOpen = useRecoilValue(isAddAccountOpenState);
  const isUpdateFormOpen = useRecoilValue(isUpdateFormOpenState);
  const isDataFormOpen = useRecoilValue(isDataFormOpenState);

  const isAnyModalOpen =
    isAccountModalOpen ||
    isAddAccountOpen ||
    isUpdateFormOpen ||
    isDataFormOpen;

  const resetIsAuthenticated = useResetRecoilState(isAuthenticatedState);
  const resetAccount = useResetRecoilState(accountState);
  const resetUser = useResetRecoilState(userState);
  const resetJWT = useResetRecoilState(jwtState);
  const resetDataCoverage = useResetRecoilState(dataCoverageState);
  const resetGoogleDataSelected = useResetRecoilState(googleDataSelectedState);
  const resetMicrosoftDataSelected = useResetRecoilState(
    microsoftDataSelectedState
  );
  const resetLsaReportsSelected = useResetRecoilState(lsaReportsSelectedState);
  const resetGoogleReportsSelected = useResetRecoilState(
    googleReportsSelectedState
  );
  const resetMicrosoftReportsSelected = useResetRecoilState(
    microsoftReportsSelectedState
  );
  const resetIsLoadingST = useResetRecoilState(isLoadingSearchTermState);
  const resetisLoadingData = useResetRecoilState(isLoadingDataState);
  const resetMonName = useResetRecoilState(monNameState);
  const resetSearchTerm30DaysAgo = useResetRecoilState(
    searchTerm30DaysAgoState
  );
  const resetBingSearchTerm30DaysAgo = useResetRecoilState(
    bingSearchTerm30DaysAgoState
  );
  const resetUserLocation30DaysAgo = useResetRecoilState(
    userLocation30DaysAgoState
  );
  const resetBingUserLocation30DaysAgo = useResetRecoilState(
    bingUserLocation30DaysAgoState
  );
  const resetAccounts = useResetRecoilState(accountsState);
  const resetthisMonthAccounts = useResetRecoilState(thisMonthAccountsState);
  const resetAccounts2MonthsAgo = useResetRecoilState(accounts2MonthsAgoState);
  const resetAccounts3MonthsAgo = useResetRecoilState(accounts3MonthsAgoState);
  const resetAccounts30DaysAgo = useResetRecoilState(accounts30DaysAgoState);
  const resetAccountsYesterday = useResetRecoilState(accountsYesterdayState);
  const resetAccounts3DaysAgo = useResetRecoilState(accounts3DaysAgoState);
  const resetAccounts90DaysAgo = useResetRecoilState(accounts90DaysAgoState);
  const resetAccountsJan = useResetRecoilState(accountsJanState);
  const resetAccountsFeb = useResetRecoilState(accountsFebState);
  const resetAccountsMar = useResetRecoilState(accountsMarState);
  const resetAccountsApr = useResetRecoilState(accountsAprState);
  const resetAccountsMay = useResetRecoilState(accountsMayState);
  const resetAccountsJun = useResetRecoilState(accountsJunState);
  const resetAccountsJul = useResetRecoilState(accountsJulState);
  const resetAccountsAug = useResetRecoilState(accountsAugState);
  const resetAccountsSep = useResetRecoilState(accountsSeptState);
  const resetAccountsOct = useResetRecoilState(accountsOctState);
  const resetAccountsNov = useResetRecoilState(accountsNovState);
  const resetAccountsDec = useResetRecoilState(accountsDecState);
  const resetSearchTermsJan = useResetRecoilState(searchTermJanState);
  const resetSearchTermsFeb = useResetRecoilState(searchTermFebState);
  const resetSearchTermsMar = useResetRecoilState(searchTermMarState);
  const resetSearchTermsApr = useResetRecoilState(searchTermAprState);
  const resetSearchTermsMay = useResetRecoilState(searchTermMayState);
  const resetSearchTermsJun = useResetRecoilState(searchTermJunState);
  const resetSearchTermsJul = useResetRecoilState(searchTermJulState);
  const resetSearchTermsAug = useResetRecoilState(searchTermAugState);
  const resetSearchTermsSep = useResetRecoilState(searchTermSeptState);
  const resetSearchTermsOct = useResetRecoilState(searchTermOctState);
  const resetSearchTermsNov = useResetRecoilState(searchTermNovState);
  const resetSearchTermsDec = useResetRecoilState(searchTermDecState);
  const resetBingSearchTermsJan = useResetRecoilState(bingSearchTermJanState);
  const resetBingSearchTermsFeb = useResetRecoilState(bingSearchTermFebState);
  const resetBingSearchTermsMar = useResetRecoilState(bingSearchTermMarState);
  const resetBingSearchTermsApr = useResetRecoilState(bingSearchTermAprState);
  const resetBingSearchTermsMay = useResetRecoilState(bingSearchTermMayState);
  const resetBingSearchTermsJun = useResetRecoilState(bingSearchTermJunState);
  const resetBingSearchTermsJul = useResetRecoilState(bingSearchTermJulState);
  const resetBingSearchTermsAug = useResetRecoilState(bingSearchTermAugState);
  const resetBingSearchTermsSep = useResetRecoilState(bingSearchTermSeptState);
  const resetBingSearchTermsOct = useResetRecoilState(bingSearchTermOctState);
  const resetBingSearchTermsNov = useResetRecoilState(bingSearchTermNovState);
  const resetBingSearchTermsDec = useResetRecoilState(bingSearchTermDecState);
  const resetUserLocationJan = useResetRecoilState(userLocationJanState);
  const resetUserLocationFeb = useResetRecoilState(userLocationFebState);
  const resetUserLocationMar = useResetRecoilState(userLocationMarState);
  const resetUserLocationApr = useResetRecoilState(userLocationAprState);
  const resetUserLocationMay = useResetRecoilState(userLocationMayState);
  const resetUserLocationJun = useResetRecoilState(userLocationJunState);
  const resetUserLocationJul = useResetRecoilState(userLocationJulState);
  const resetUserLocationAug = useResetRecoilState(userLocationAugState);
  const resetUserLocationSep = useResetRecoilState(userLocationSeptState);
  const resetUserLocationOct = useResetRecoilState(userLocationOctState);
  const resetUserLocationNov = useResetRecoilState(userLocationNovState);
  const resetUserLocationDec = useResetRecoilState(userLocationDecState);
  const resetBingUserLocationJan = useResetRecoilState(
    bingUserLocationJanState
  );
  const resetBingUserLocationFeb = useResetRecoilState(
    bingUserLocationFebState
  );
  const resetBingUserLocationMar = useResetRecoilState(
    bingUserLocationMarState
  );
  const resetBingUserLocationApr = useResetRecoilState(
    bingUserLocationAprState
  );
  const resetBingUserLocationMay = useResetRecoilState(
    bingUserLocationMayState
  );
  const resetBingUserLocationJun = useResetRecoilState(
    bingUserLocationJunState
  );
  const resetBingUserLocationJul = useResetRecoilState(
    bingUserLocationJulState
  );
  const resetBingUserLocationAug = useResetRecoilState(
    bingUserLocationAugState
  );
  const resetBingUserLocationSep = useResetRecoilState(
    bingUserLocationSeptState
  );
  const resetBingUserLocationOct = useResetRecoilState(
    bingUserLocationOctState
  );
  const resetBingUserLocationNov = useResetRecoilState(
    bingUserLocationNovState
  );
  const resetBingUserLocationDec = useResetRecoilState(
    bingUserLocationDecState
  );
  const resetSelectedAccount = useResetRecoilState(selectedAccountState);
  const resetSearchTermFilter = useResetRecoilState(searchTermFilterState);
  const resetAccountManagersFilter = useResetRecoilState(
    accountManagersFilterState
  );
  const resetAccManagerOptions = useResetRecoilState(accManagerOptionState);
  const resetPPCSpecialistsFilter = useResetRecoilState(
    ppcSpecialistsFilterState
  );
  const resetPPCFilterOptions = useResetRecoilState(ppcFilterOptionState);
  const resetIDXPlatformsFilter = useResetRecoilState(idxPlatformsFilterState);
  const resetIDXOptionsFilter = useResetRecoilState(idxFilterOptionsState);
  const resetStatesFilter = useResetRecoilState(statesFilterState);
  const resetStatesOptions = useResetRecoilState(statesOptionState);
  const resetUserLocationFilter = useResetRecoilState(userLocationFilterState);
  const resetDeviceChartFilter = useResetRecoilState(deviceChartFilterState);
  const resetOverviewUserLocation = useResetRecoilState(
    overviewUserLocationState
  );
  const resetOverviewPrimitiveData = useResetRecoilState(
    overviewPrimitiveDataState
  );
  const resetOverviewKeyword = useResetRecoilState(overviewKeywordState);
  const resetOverviewFirstDoughnut = useResetRecoilState(
    overviewFirstDoughnutChartState
  );
  const resetOverviewSecondDoughnut = useResetRecoilState(
    overviewSecondDoughnutChartState
  );
  const resetOverviewLineGraph = useResetRecoilState(overviewLineGraphState);
  const resetReportBoxOne = useResetRecoilState(reportBoxOneState);
  const resetReportBoxTwo = useResetRecoilState(reportBoxTwoState);
  const resetReportBoxThree = useResetRecoilState(reportBoxThreeState);
  const resetFirstPlatformChart = useResetRecoilState(firstPlatformChartState);
  const resetSecondPlatformChart = useResetRecoilState(
    secondPlatformChartState
  );
  const resetPlatformFilter = useResetRecoilState(platformFilterState);
  const resetPlatformOptions = useResetRecoilState(platformOptionState);
  const resetIsEditingPlatform = useResetRecoilState(isEditingColumsState);
  const resetColumn2 = useResetRecoilState(column2State);
  const resetColumn3 = useResetRecoilState(column3State);
  const resetColumn4 = useResetRecoilState(column4State);
  const resetColumn5 = useResetRecoilState(column5State);
  const resetColumn6 = useResetRecoilState(column6State);
  const resetColumn7 = useResetRecoilState(column7State);
  const resetColumn8 = useResetRecoilState(column8State);
  const resetColumn9 = useResetRecoilState(column9State);
  const resetColumn10 = useResetRecoilState(column10State);
  const resetColumn11 = useResetRecoilState(column11State);
  const resetColumn12 = useResetRecoilState(column12State);
  const resetThisMoActiveCol = useResetRecoilState(thisMoActiveColumnsState);
  const resetThisMoInactiveCol = useResetRecoilState(thisMoInactiveColumnState);
  const resetActiveCol = useResetRecoilState(activeColumnState);
  const resetInactiveCol = useResetRecoilState(inactiveColumnState);
  const resetIsOpenProfModal = useResetRecoilState(isOpenProfModalState);
  const resetPlatformCharts = useResetRecoilState(platformChartsPrintState);
  const resetdeviceCharts = useResetRecoilState(deviceChartsPrintState);
  const resetCoverageGraph = useResetRecoilState(coverageGraphPrintState);
  const resetRemarketing = useResetRecoilState(remarketingPrintState);
  const resetIsOpenNotifBox = useResetRecoilState(isOpenNotifBoxState);
  const resetIsOpenNotifPopup = useResetRecoilState(isOpenNotifPopupState);
  const resetSearch = useResetRecoilState(searchState);

  const arrayResetters = [
    resetBingUserLocation30DaysAgo,
    resetBingSearchTerm30DaysAgo,
    resetUserLocation30DaysAgo,
    resetSearchTerm30DaysAgo,
    resetAccounts,
    resetthisMonthAccounts,
    resetAccounts2MonthsAgo,
    resetAccounts3MonthsAgo,
    resetAccounts30DaysAgo,
    resetAccountsYesterday,
    resetAccounts3DaysAgo,
    resetAccounts90DaysAgo,
    resetAccountsJan,
    resetAccountsFeb,
    resetAccountsMar,
    resetAccountsApr,
    resetAccountsMay,
    resetAccountsJun,
    resetAccountsJul,
    resetAccountsAug,
    resetAccountsSep,
    resetAccountsOct,
    resetAccountsNov,
    resetAccountsDec,
    resetSearchTermsJan,
    resetSearchTermsFeb,
    resetSearchTermsMar,
    resetSearchTermsApr,
    resetSearchTermsMay,
    resetSearchTermsJun,
    resetSearchTermsJul,
    resetSearchTermsAug,
    resetSearchTermsSep,
    resetSearchTermsOct,
    resetSearchTermsNov,
    resetSearchTermsDec,
    resetBingSearchTermsJan,
    resetBingSearchTermsFeb,
    resetBingSearchTermsMar,
    resetBingSearchTermsApr,
    resetBingSearchTermsMay,
    resetBingSearchTermsJun,
    resetBingSearchTermsJul,
    resetBingSearchTermsAug,
    resetBingSearchTermsSep,
    resetBingSearchTermsOct,
    resetBingSearchTermsNov,
    resetBingSearchTermsDec,
    resetUserLocationJan,
    resetUserLocationFeb,
    resetUserLocationMar,
    resetUserLocationApr,
    resetUserLocationMay,
    resetUserLocationJun,
    resetUserLocationJul,
    resetUserLocationAug,
    resetUserLocationSep,
    resetUserLocationOct,
    resetUserLocationNov,
    resetUserLocationDec,
    resetBingUserLocationJan,
    resetBingUserLocationFeb,
    resetBingUserLocationMar,
    resetBingUserLocationApr,
    resetBingUserLocationMay,
    resetBingUserLocationJun,
    resetBingUserLocationJul,
    resetBingUserLocationAug,
    resetBingUserLocationSep,
    resetBingUserLocationOct,
    resetBingUserLocationNov,
    resetBingUserLocationDec,
    resetIsAuthenticated,
    resetAccount,
    resetUser,
    resetJWT,
    resetDataCoverage,
    resetGoogleDataSelected,
    resetMicrosoftDataSelected,
    resetLsaReportsSelected,
    resetGoogleReportsSelected,
    resetMicrosoftReportsSelected,
    resetIsLoadingST,
    resetisLoadingData,
    resetMonName,
    resetSelectedAccount,
    resetSearchTermFilter,
    resetAccountManagersFilter,
    resetAccManagerOptions,
    resetPPCSpecialistsFilter,
    resetPPCFilterOptions,
    resetIDXPlatformsFilter,
    resetIDXOptionsFilter,
    resetStatesFilter,
    resetStatesOptions,
    resetUserLocationFilter,
    resetDeviceChartFilter,
    resetOverviewUserLocation,
    resetOverviewPrimitiveData,
    resetOverviewKeyword,
    resetOverviewFirstDoughnut,
    resetOverviewSecondDoughnut,
    resetOverviewLineGraph,
    resetReportBoxOne,
    resetReportBoxTwo,
    resetReportBoxThree,
    resetFirstPlatformChart,
    resetSecondPlatformChart,
    resetPlatformFilter,
    resetPlatformOptions,
    resetIsEditingPlatform,
    resetColumn2,
    resetColumn3,
    resetColumn4,
    resetColumn5,
    resetColumn6,
    resetColumn7,
    resetColumn8,
    resetColumn9,
    resetColumn10,
    resetColumn11,
    resetColumn12,
    resetThisMoActiveCol,
    resetThisMoInactiveCol,
    resetActiveCol,
    resetInactiveCol,
    resetIsOpenProfModal,
    resetPlatformCharts,
    resetdeviceCharts,
    resetCoverageGraph,
    resetRemarketing,
    resetIsOpenNotifBox,
    resetIsOpenNotifPopup,
    resetSearch,
  ];

  const handleSignOut = () => {
    if (handleClose) handleClose();
    setTimeout(async () => {
      await signOut()
        .then(() => {
          arrayResetters.forEach((reset) => reset());
        })
        .catch((err) => {
          console.log(err);
          swal(
            'Error!',
            'Had a problem signing out, please try again.',
            'error'
          );
        });
    }, 700);
  };

  return (
    <button
      onClick={handleSignOut}
      className={`font-semibold xs:font-normal inline-flex items-center hover:no-underline text-white border-0 py-1 px-3 focus:outline-none transition duration-300 ${
        lightMode
          ? 'bg-red-400 hover:bg-red-500'
          : 'bg-red-600 hover:bg-red-700'
      } rounded text-sm xs:text-base md:mt-0 `}
      disabled={isAnyModalOpen}
    >
      Log Out
    </button>
  );
};

export default LogOutButton;
