import React from 'react';
import { Line } from 'react-chartjs-2';
import { month, getIndexOfMonth } from '../../helpers/dateUtils';
import { lightModeState, pageState } from '../../recoil/atoms';
import { type SetterOrUpdater, useRecoilValue } from 'recoil';
import { RiArrowDownSFill } from 'react-icons/ri';

type Props = {
  title: string;
  data1: number;
  data2: number;
  data3: number;
  data4: number;
  backgroundColor: string;
  borderColor: string;
  pointBorderColor: string;
  isPrint?: boolean;
  setTitle?: SetterOrUpdater<string>;
};

const LineGraph = ({
  title,
  data1,
  data2,
  data3,
  data4,
  backgroundColor,
  borderColor,
  pointBorderColor,
  isPrint,
  setTitle,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const page = useRecoilValue(pageState);
  const threeMoAgo = month[getIndexOfMonth(3)];
  const twoMoAgo = month[getIndexOfMonth(2)];
  const oneMoAgo = month[getIndexOfMonth(1)];
  const data = {
    labels: [threeMoAgo, twoMoAgo, oneMoAgo, '30-Day'],
    datasets: [
      {
        label: title,
        fill: true,
        lineTension: 0.3,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: pointBorderColor,
        pointBackgroundColor: 'rgb(255, 255, 255)',
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(0, 0, 0)',
        pointHoverBorderColor: 'rgba(220, 220, 220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [data1, data2, data3, data4, 0],
      },
    ],
  };

  const options = {
    responsive: true,
    events: isPrint
      ? ['']
      : ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    plugins: {
      legend: {
        labels: {
          color: lightMode || isPrint ? '#323232' : '#A6A9B6',
        },
      },
    },
  };
  return (
    <div
      className={`mx-auto ${
        isPrint ? 'sm:w-full sm:h-full' : 'sm:w-2/3 sm:h-2/3'
      } text-center`}
    >
      {page === 'reports' && (
        <div className='dropdown dropdown-hover dropdown-end z-10 mb-5 group'>
          <h3
            className={`text-lg sm:text-xl mt-5 text-center ${
              setTitle && !isPrint && 'cursor-pointer'
            } rounded ${
              lightMode || isPrint
                ? `text-gray-900 ${
                    setTitle &&
                    !isPrint &&
                    'group-hover:shadow group-hover:bg-gray-200'
                  }`
                : `text-white ${
                    setTitle &&
                    !isPrint &&
                    'group-hover:shadow group-hover:bg-green-800'
                  }`
            } flex px-2 py-1 items-center gap-x-2 `}
            tabIndex={0}
          >
            {title} {setTitle && !isPrint && <RiArrowDownSFill />}
          </h3>
          {setTitle && !isPrint && (
            <ul
              tabIndex={0}
              className={`dropdown-content border-t border-b border-l border-r menu w-44 ${
                lightMode
                  ? 'bg-gray-200 border-gray-400 text-gray-900'
                  : 'bg-green-800 border-green-500 text-white'
              } text-left menu rounded text-2xs sm:text-sm font-semibold uppercase w-full`}
            >
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('Leads') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Leads')}
              >
                Leads
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('Budget') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Budget')}
              >
                Budget
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('CPL') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('CPL')}
              >
                CPL
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300 ' : 'hover:bg-green-700'
                } ${
                  title.includes('Impressions') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Impressions')}
              >
                Impressions
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('Clicks') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('Clicks')}
              >
                Clicks
              </li>
              <li
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-300' : 'hover:bg-green-700'
                } ${
                  title.includes('CTR') &&
                  (lightMode ? 'bg-gray-300' : 'bg-green-700')
                }`}
                onClick={() => setTitle('CTR')}
              >
                CTR
              </li>
            </ul>
          )}
        </div>
      )}
      <Line type='line' data={data} options={options} />
    </div>
  );
};

export default LineGraph;
