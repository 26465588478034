import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { auth2, firestore } from '../../../firebase/firebase';
import { isLoadingDataState, lightModeState } from '../../../recoil/atoms';
import swal from 'sweetalert';
import { IoMdArrowDropdown } from 'react-icons/io';

const AddUserInternal = () => {
  const [role, setRole] = useState('admin');
  const lightMode = useRecoilValue(lightModeState);
  const setIsLoading = useSetRecoilState(isLoadingDataState);
  const [details, setDetails] = useState<{
    displayName: string;
    email: string;
    googleCID: number | undefined;
    microsoftCID: string;
  }>({
    displayName: '',
    email: '',
    googleCID: undefined,
    microsoftCID: '',
  });
  const [showRole, setShowRole] = useState(false);

  const reqDetails: {
    displayName: string;
    email: string;
    googleCID?: number;
    microsoftCID?: string;
    role: string;
  } = {
    displayName: details.displayName,
    email: details.email,
    role,
  };

  if (details.googleCID) reqDetails.googleCID = details.googleCID;
  if (details.microsoftCID) reqDetails.microsoftCID = details.microsoftCID;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let v: string | number = value;
    if (name === 'googleCID') v = parseFloat(value);
    setDetails({ ...details, [name]: v });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await auth2
      .createUserWithEmailAndPassword(details.email, 'Asterisk2022!')
      .then(
        async ({ user }) =>
          await firestore
            .collection('users')
            .doc(user?.uid)
            .set(
              role === 'admin'
                ? reqDetails
                : {
                    displayName: details.displayName,
                    email: details.email,
                    role: role,
                  }
            )
            .then(() => {
              swal(
                'Succefully Changed Password',
                'User Has Been Added',
                'success',
                {
                  timer: 1000,
                  buttons: [false],
                }
              );
              setIsLoading(false);
            })
            .catch((err) => {
              swal('Unable to add user', err.message, 'error');
              setIsLoading(false);
            })
      )
      .catch((err) => {
        setIsLoading(false);
        swal('Unable to add user', err.message, 'error');
      });
  };

  return (
    <div className='w-screen sm:w-full flex flex-col  h-full'>
      <div className='my-auto md:pt-0'>
        <form className='mx-4 sm:mx-0 flex flex-col' onSubmit={handleSubmit}>
          <div className='flex flex-col pt-4'>
            <label
              htmlFor='role'
              className={`text-lg ${
                lightMode ? 'text-gray-900' : 'text-white'
              }`}
            >
              Role
            </label>

            <div className='dropdown z-10 ' id='role'>
              <label
                tabIndex={0}
                className={`appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full flex justify-between items-center ${
                  lightMode
                    ? 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
                    : 'bg-green-700 text-white shadow'
                } cursor-pointer capitalize font-semibold`}
                onClick={() => setShowRole(!showRole)}
              >
                {role} <IoMdArrowDropdown />
              </label>

              <ul
                tabIndex={0}
                className={`${
                  showRole ? 'dropdown-content' : 'hidden'
                } menu p-2 shadow ${
                  lightMode
                    ? 'border-r border-l border-t border-b border-gray-400 text-gray-900 bg-white'
                    : 'bg-green-700 text-white'
                } rounded w-full font-semibold`}
              >
                <li
                  onClick={() => setRole('admin')}
                  className={`p-2 transition duration-300 ${
                    role === 'admin' &&
                    (lightMode ? 'bg-gray-200' : 'bg-green-500')
                  } cursor-pointer rounded`}
                >
                  Admin
                </li>
                <li
                  onClick={() => setRole('superadmin')}
                  className={`p-2 transition duration-300 ${
                    role === 'superadmin' &&
                    (lightMode ? 'bg-gray-200' : 'bg-green-500')
                  }  cursor-pointer rounded`}
                >
                  Superadmin
                </li>
              </ul>
            </div>
          </div>
          <div className='flex flex-col pt-4'>
            <label
              htmlFor='displayName'
              className={`text-lg ${
                lightMode ? 'text-gray-900' : 'text-white'
              }`}
            >
              Name
            </label>

            <input
              type='text'
              id='displayName'
              placeholder='John Smith'
              onChange={handleChange}
              name='displayName'
              value={details.displayName}
              className={` appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                lightMode
                  ? 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
                  : 'bg-green-700 text-white shadow'
              }`}
            />
          </div>
          <div className='flex flex-col pt-4'>
            <label
              htmlFor='email'
              className={`text-lg ${
                lightMode ? 'text-gray-900' : 'text-white'
              }`}
            >
              Email
            </label>

            <input
              type='email'
              id='email'
              placeholder='user@email.com'
              onChange={handleChange}
              name='email'
              value={details.email}
              className={` appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                lightMode
                  ? 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
                  : 'bg-green-700 text-white shadow'
              }`}
            />
          </div>
          {role === 'admin' && (
            <div className='flex flex-col pt-4'>
              <label
                htmlFor='googleCID'
                className={`text-lg ${
                  lightMode ? 'text-gray-900' : 'text-white'
                }`}
              >
                Google CID
              </label>

              <input
                type='number'
                id='googleCID'
                placeholder='123123123'
                onChange={handleChange}
                name='googleCID'
                value={details.googleCID}
                className={` appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full number-remove-arrows ${
                  lightMode
                    ? 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
                    : 'bg-green-700 text-white shadow'
                }`}
              />
            </div>
          )}
          {role === 'admin' && (
            <div className='flex flex-col pt-4'>
              <label
                htmlFor='microsoftCID'
                className={`text-lg ${
                  lightMode ? 'text-gray-900' : 'text-white'
                }`}
              >
                Microsoft CID
              </label>

              <input
                type='text'
                id='microsoftCID'
                placeholder='F1012345'
                onChange={handleChange}
                name='microsoftCID'
                value={details.microsoftCID}
                className={` appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                  lightMode
                    ? 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
                    : 'bg-green-700 text-white shadow'
                }`}
              />
            </div>
          )}

          <button
            type='submit'
            className={`${
              lightMode
                ? 'bg-gray-800 hover:bg-black '
                : 'bg-red-800 hover:bg-red-600'
            }  transition duration-300 text-white font-bold text-lg p-2 mt-12 rounded w-full`}
          >
            Add User
          </button>
        </form>
        <div className='text-center pt-12 pb-12'></div>
      </div>
    </div>
  );
};

export default AddUserInternal;
