import React from 'react';
import OverviewInfoBox from '../info-boxes-templates/OverviewInfoBox';
import LeadsDoughnutChart from '../graphs/LeadsDoughnutChart';
import BudgetDoughnutChart from '../graphs/BudgetDoughnutChart';
import LeadsDeviceDoughnutChart from '../graphs/LeadsDeviceDoughnutChart';
import LeadsDeviceBingDoughnutChart from '../graphs/LeadsDeviceBingDoughnutChart';
import LeadsLineGraph from '../graphs/LeadsLineGraph';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  userState,
  lightModeState,
  isLoadingSearchTermState,
  monNameState,
  accountState,
  searchTermAprState,
  searchTermAugState,
  searchTermDecState,
  searchTermFebState,
  searchTermJanState,
  searchTermJulState,
  searchTermJunState,
  searchTermMarState,
  searchTermMayState,
  searchTermNovState,
  searchTermOctState,
  searchTermSeptState,
  bingSearchTermAprState,
  bingSearchTermAugState,
  bingSearchTermDecState,
  bingSearchTermFebState,
  bingSearchTermJanState,
  bingSearchTermJulState,
  bingSearchTermJunState,
  bingSearchTermMarState,
  bingSearchTermMayState,
  bingSearchTermNovState,
  bingSearchTermOctState,
  bingSearchTermSeptState,
  userLocationAprState,
  userLocationAugState,
  userLocationDecState,
  userLocationFebState,
  userLocationJanState,
  userLocationJulState,
  userLocationJunState,
  userLocationMarState,
  userLocationMayState,
  userLocationNovState,
  userLocationOctState,
  userLocationSeptState,
  bingUserLocationAprState,
  bingUserLocationAugState,
  bingUserLocationDecState,
  bingUserLocationFebState,
  bingUserLocationJanState,
  bingUserLocationJulState,
  bingUserLocationJunState,
  bingUserLocationMarState,
  bingUserLocationMayState,
  bingUserLocationNovState,
  bingUserLocationOctState,
  bingUserLocationSeptState,
  searchTerm30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  isLoadingUserLocationState,
  userLocation30DaysAgoState,
  bingUserLocation30DaysAgoState,
  overviewUserLocationState,
  overviewPrimitiveDataState,
  overviewKeywordState,
  overviewFirstDoughnutChartState,
  overviewSecondDoughnutChartState,
  overviewLineGraphState,
} from '../../recoil/atoms';
import { month } from '../../helpers/dateUtils';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { Commaed } from '../../helpers/utils';
import { SearchTerm, UserLocation } from '../../helpers/types';
import { FaArrowUp } from 'react-icons/fa';
import BudgetLineGraph from '../graphs/BudgetLineGraph';
import BudgetDeviceDoughnutChart from '../graphs/BudgetDeviceDoughnutChart';
import BudgetDeviceBingDoughnutChart from '../graphs/BudgetDeviceBingDoughnutChart';

const OverviewComponent = () => {
  const searchTermMonths = [
    searchTermJanState,
    searchTermFebState,
    searchTermMarState,
    searchTermAprState,
    searchTermMayState,
    searchTermJunState,
    searchTermJulState,
    searchTermAugState,
    searchTermSeptState,
    searchTermOctState,
    searchTermNovState,
    searchTermDecState,
  ];
  const bingSearchTermMonths = [
    bingSearchTermJanState,
    bingSearchTermFebState,
    bingSearchTermMarState,
    bingSearchTermAprState,
    bingSearchTermMayState,
    bingSearchTermJunState,
    bingSearchTermJulState,
    bingSearchTermAugState,
    bingSearchTermSeptState,
    bingSearchTermOctState,
    bingSearchTermNovState,
    bingSearchTermDecState,
  ];
  const userLocationMonths = [
    userLocationJanState,
    userLocationFebState,
    userLocationMarState,
    userLocationAprState,
    userLocationMayState,
    userLocationJunState,
    userLocationJulState,
    userLocationAugState,
    userLocationSeptState,
    userLocationOctState,
    userLocationNovState,
    userLocationDecState,
  ];
  const bingUserLocationMonths = [
    bingUserLocationJanState,
    bingUserLocationFebState,
    bingUserLocationMarState,
    bingUserLocationAprState,
    bingUserLocationMayState,
    bingUserLocationJunState,
    bingUserLocationJulState,
    bingUserLocationAugState,
    bingUserLocationSeptState,
    bingUserLocationOctState,
    bingUserLocationNovState,
    bingUserLocationDecState,
  ];
  const userLocationKeywordOptions = [
    'Most Impressions',
    'Most Clicks',
    'Highest CTR',
    'Lowest CPC',
    'Least Spending',
    'Most Converting',
    'Lowest CPL',
  ];
  const primitiveOptions = [
    'Total Conversions',
    'Total Ad Investment',
    'Total CPL',
    'Total Impressions',
    'Total Clicks',
    'Total CTR',
  ];

  const firstDoughnutOptions = ['Leads Per Platform', 'Budget Per Platform'];

  const secondDoughnutOptions = [
    'Leads Per Device (Google)',
    'Leads Per Device (Microsoft)',
    'Budget Per Device (Google)',
    'Budget Per Device (Microsoft)',
  ];

  const lineChartOptions = ['Leads Per Coverage', 'Budget Per Coverage'];
  const lightMode = useRecoilValue(lightModeState);
  const isLoadingST = useRecoilValue(isLoadingSearchTermState);
  const isLoadingUL = useRecoilValue(isLoadingUserLocationState);
  const monName = useRecoilValue(monNameState);
  const user = useRecoilValue(userState);
  const superadminAccount = useRecoilValue(accountState);
  const adminAccount = useRecoilValue(adminAccountState);
  const [overviewUserLocation, setOverviewUserLocation] = useRecoilState(
    overviewUserLocationState
  );
  const [overviewPrimitiveData, setPrimitiveData] = useRecoilState(
    overviewPrimitiveDataState
  );
  const [overviewKeyword, setOverviewKeyword] =
    useRecoilState(overviewKeywordState);
  const [overviewFirstDoughnut, setFirstOverviewDoughnut] = useRecoilState(
    overviewFirstDoughnutChartState
  );
  const [overviewSecondDoughnut, setSecondDoughnut] = useRecoilState(
    overviewSecondDoughnutChartState
  );
  const [overviewLineGraph, setOverviewLineGraph] = useRecoilState(
    overviewLineGraphState
  );
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;

  let convertingUL: UserLocation[] | null = useRecoilValue(
    monName === '30-Day' || monName === 'All Time'
      ? userLocation30DaysAgoState
      : userLocationMonths[month.indexOf(monName)]
  )
    ?.filter((item) =>
      account && account.google_cid ? item.account === account.google_cid : item
    )
    .filter((item) =>
      overviewUserLocation === 'Least Spending' ||
      overviewUserLocation === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );

  let bingConvertingUL: UserLocation[] | null = useRecoilValue(
    monName === '30-Day' || monName === 'All Time'
      ? bingUserLocation30DaysAgoState
      : bingUserLocationMonths[month.indexOf(monName)]
  )
    ?.filter((item) =>
      account && account.microsoft_cid
        ? item.account === account.microsoft_cid
        : item
    )
    .filter((item) =>
      overviewUserLocation === 'Least Spending' ||
      overviewUserLocation === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );

  let convertingST: SearchTerm[] | null = useRecoilValue(
    monName === '30-Day' || monName === 'All Time'
      ? searchTerm30DaysAgoState
      : searchTermMonths[month.indexOf(monName)]
  )
    .filter((item) =>
      account && account.google_cid ? item.account === account.google_cid : item
    )
    .filter((item) =>
      overviewKeyword === 'Least Spending' || overviewKeyword === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );

  let bingConvertingST: SearchTerm[] | null = useRecoilValue(
    monName === '30-Day' || monName === 'All Time'
      ? bingSearchTerm30DaysAgoState
      : bingSearchTermMonths[month.indexOf(monName)]
  )
    ?.filter((item) =>
      account && account.microsoft_cid
        ? item.account === account.microsoft_cid
        : item
    )
    .filter((item) =>
      overviewKeyword === 'Least Spending' || overviewKeyword === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );

  if (monName === 'All Time') {
    convertingST = null;
    bingConvertingST = null;
    convertingUL = null;
    bingConvertingUL = null;
  }
  let accountConversions = 0;
  let accountSpend = 0;
  let accountCPL = 0;
  let accountImpressions = 0;
  let accountClicks = 0;
  let accountCTR = 0;

  if (account && account.name !== 'None') {
    convertingUL?.sort((a, b) =>
      overviewUserLocation === 'Most Converting'
        ? b.conversions - a.conversions
        : overviewUserLocation === 'Most Impressions'
        ? b.impressions - a.impressions
        : overviewUserLocation === 'Most Clicks'
        ? b.clicks - a.clicks
        : overviewUserLocation === 'Highest CTR'
        ? b.ctr - a.ctr
        : overviewUserLocation === 'Lowest CPC'
        ? a.avg_cpc - b.avg_cpc
        : overviewUserLocation === 'Least Spending'
        ? a.spend - b.spend
        : a.cost_per_conversion - b.cost_per_conversion
    );
    bingConvertingUL?.sort((a, b) =>
      overviewUserLocation === 'Most Converting'
        ? b.conversions - a.conversions
        : overviewUserLocation === 'Most Impressions'
        ? b.impressions - a.impressions
        : overviewUserLocation === 'Most Clicks'
        ? b.clicks - a.clicks
        : overviewUserLocation === 'Highest CTR'
        ? b.ctr - a.ctr
        : overviewUserLocation === 'Lowest CPC'
        ? a.avg_cpc - b.avg_cpc
        : overviewUserLocation === 'Least Spending'
        ? a.spend - b.spend
        : a.cost_per_conversion - b.cost_per_conversion
    );
    convertingST?.sort((a, b) =>
      overviewKeyword === 'Most Converting'
        ? b.conversions - a.conversions
        : overviewKeyword === 'Most Impressions'
        ? b.impressions - a.impressions
        : overviewKeyword === 'Most Clicks'
        ? b.clicks - a.clicks
        : overviewKeyword === 'Highest CTR'
        ? b.ctr - a.ctr
        : overviewKeyword === 'Lowest CPC'
        ? a.avg_cpc - b.avg_cpc
        : overviewKeyword === 'Least Spending'
        ? a.spend - b.spend
        : a.cost_per_conversion - b.cost_per_conversion
    );
    bingConvertingST?.sort((a, b) =>
      overviewKeyword === 'Most Converting'
        ? b.conversions - a.conversions
        : overviewKeyword === 'Most Impressions'
        ? b.impressions - a.impressions
        : overviewKeyword === 'Most Clicks'
        ? b.clicks - a.clicks
        : overviewKeyword === 'Highest CTR'
        ? b.ctr - a.ctr
        : overviewKeyword === 'Lowest CPC'
        ? a.avg_cpc - b.avg_cpc
        : overviewKeyword === 'Least Spending'
        ? a.spend - b.spend
        : a.cost_per_conversion - b.cost_per_conversion
    );
    accountConversions =
      account.google_account && account.microsoft_account
        ? parseFloat(account.google_account.conversions) +
          parseFloat(account.microsoft_account.conversions)
        : account.google_account
        ? parseFloat(account.google_account.conversions)
        : account.microsoft_account
        ? parseFloat(account.microsoft_account.conversions)
        : 0;

    accountSpend =
      account.google_account && account.microsoft_account
        ? parseFloat(account.google_account.spend) +
          parseFloat(account.microsoft_account.spend)
        : account.google_account
        ? parseFloat(account.google_account.spend)
        : account.microsoft_account
        ? parseFloat(account.microsoft_account.spend)
        : 0;

    accountCPL =
      account.google_account && account.microsoft_account
        ? accountSpend / accountConversions
        : account.google_account
        ? parseFloat(account.google_account.cost_per_conversion)
        : account.microsoft_account
        ? parseFloat(account.microsoft_account.cost_per_conversion)
        : 0;

    accountConversions = account.lsa_account
      ? accountConversions + parseFloat(account.lsa_account.conversions)
      : accountConversions;

    accountImpressions =
      account.google_account && account.microsoft_account
        ? parseFloat(account.google_account.impressions) +
          parseFloat(account.microsoft_account.impressions)
        : account.google_account
        ? parseFloat(account.google_account.impressions)
        : account.microsoft_account
        ? parseFloat(account.microsoft_account.impressions)
        : 0;

    accountClicks =
      account.google_account && account.microsoft_account
        ? parseFloat(account.google_account.clicks) +
          parseFloat(account.microsoft_account.clicks)
        : account.google_account
        ? parseFloat(account.google_account.clicks)
        : account.microsoft_account
        ? parseFloat(account.microsoft_account.clicks)
        : 0;

    accountCTR =
      account.google_account && account.microsoft_account
        ? (accountClicks / accountImpressions) * 100
        : account.google_account
        ? parseFloat(account.google_account.ctr)
        : account.microsoft_account
        ? parseFloat(account.microsoft_account.ctr)
        : 0;
  }

  const convertingSTName = convertingST && convertingST[0]?.name;
  const bingConvertingSTName = bingConvertingST && bingConvertingST[0]?.name;
  const convertingULName = convertingUL
    ? convertingUL[0]?.city + ', ' + convertingUL[0]?.state
    : null;
  const bingConvertingULName = bingConvertingUL
    ? bingConvertingUL[0]?.city + ', ' + bingConvertingUL[0]?.state
    : null;

  const scrollToTop = () => {
    for (let i = window.scrollY; i > 0; i = i - 0.04) {
      setTimeout(() => {
        window.scrollTo(0, i);
      }, 500);
    }
  };

  return (
    <div
      className={`block  ${
        lightMode ? 'shadowmd bg-gray-100' : 'shadow2xl bg-green-900'
      } px-3 py-10 sm:mx-16 sm:rounded-lg animate__animated animate__zoomIn transition duration-200 scroll-smooth`}
    >
      <div
        className={` ${
          lightMode
            ? 'bg-green-500 text-white'
            : 'border-2 border-gray-400 text-white'
        } sm:mx-9 rounded sm:text-2xl text-center sm:h-16 shadow-sm`}
      >
        <p className='font-sans sm:py-3 tracking-wider '>OVERVIEW</p>
      </div>

      <div>
        <div className='space-y-5 sm:space-y-0 sm:mx-9 sm:grid sm:grid-cols-3 gap-x-5 mt-3'>
          <OverviewInfoBox
            label={
              overviewUserLocation +
              ' User Location' +
              (overviewUserLocation === 'Least Spending' ||
              overviewUserLocation === 'Lowest CPL'
                ? ' W/ Conversions'
                : '')
            }
            data={
              <div className='font-semibold'>
                <span
                  className={`italic font-normal ${
                    lightMode ? 'text-gray-800' : 'text-gray-300'
                  }`}
                >
                  Google:
                </span>{' '}
                {convertingUL && convertingUL[0] ? (
                  convertingULName
                ) : isLoadingUL && monName !== 'All Time' ? (
                  <span
                    className={`font-normal text-sm italic ${
                      lightMode
                        ? 'text-gray-800 hover:text-black'
                        : 'text-gray-400 hover:text-gray-200'
                    }`}
                  >
                    Loading User Locations...
                  </span>
                ) : (
                  <span
                    className={`p-1 font-normal text-sm italic text-gray-500  ${
                      lightMode ? 'hover:text-black' : 'hover:text-gray-400'
                    } hover:no-underline`}
                  >
                    No User Location Available
                  </span>
                )}
                <br />
                <span
                  className={`italic font-normal ${
                    lightMode ? 'text-gray-800' : 'text-gray-300'
                  }`}
                >
                  Microsoft:
                </span>{' '}
                {bingConvertingUL && bingConvertingUL[0] ? (
                  bingConvertingULName
                ) : isLoadingUL && monName !== 'All Time' ? (
                  <span
                    className={`font-normal text-sm italic ${
                      lightMode
                        ? 'text-gray-800 hover:text-black'
                        : 'text-gray-400 hover:text-gray-200'
                    }`}
                  >
                    Loading User Locations...
                  </span>
                ) : (
                  <span
                    className={`p-1 font-normal text-sm italic text-gray-500  ${
                      lightMode ? 'hover:text-black' : 'hover:text-gray-400'
                    } hover:no-underline`}
                  >
                    No User Location Available
                  </span>
                )}
              </div>
            }
            options={userLocationKeywordOptions}
            setLabel={setOverviewUserLocation}
          />
          <OverviewInfoBox
            label={overviewPrimitiveData}
            data={
              overviewPrimitiveData === 'Total Conversions'
                ? Commaed(accountConversions.toFixed(2))
                : overviewPrimitiveData === 'Total Ad Investment'
                ? Commaed(accountSpend.toFixed(2))
                : overviewPrimitiveData === 'Total CPL'
                ? Commaed(accountCPL.toFixed(2))
                : overviewPrimitiveData === 'Total Impressions'
                ? Commaed(accountImpressions.toFixed(2))
                : overviewPrimitiveData === 'Total Clicks'
                ? Commaed(accountClicks.toFixed(2))
                : Commaed(accountCTR.toFixed(2))
            }
            options={primitiveOptions}
            setLabel={setPrimitiveData}
          />
          <OverviewInfoBox
            label={
              overviewKeyword +
              ' Keyword' +
              (overviewKeyword === 'Least Spending' ||
              overviewKeyword === 'Lowest CPL'
                ? ' W/ Conversions'
                : '')
            }
            data={
              <div className='font-semibold'>
                <span
                  className={`italic font-normal ${
                    lightMode ? 'text-gray-800' : 'text-gray-300'
                  }`}
                >
                  Google:
                </span>{' '}
                {convertingST && convertingST[0] ? (
                  convertingSTName
                ) : isLoadingST && monName !== 'All Time' ? (
                  <span
                    className={`font-normal text-sm italic ${
                      lightMode
                        ? 'text-gray-800 hover:text-black'
                        : 'text-gray-400 hover:text-gray-200'
                    }`}
                  >
                    Loading Keywords...
                  </span>
                ) : (
                  <span
                    className={`p-1 font-normal text-sm italic text-gray-500  ${
                      lightMode ? 'hover:text-black' : 'hover:text-gray-400'
                    } hover:no-underline`}
                  >
                    No Keywords Available
                  </span>
                )}
                <br />
                <span
                  className={`italic font-normal ${
                    lightMode ? 'text-gray-800' : 'text-gray-300'
                  }`}
                >
                  Microsoft:
                </span>{' '}
                {bingConvertingST && bingConvertingST[0] ? (
                  bingConvertingSTName
                ) : isLoadingST && monName !== 'All Time' ? (
                  <span
                    className={`font-normal text-sm italic ${
                      lightMode
                        ? 'text-gray-800 hover:text-black'
                        : 'text-gray-400 hover:text-gray-200'
                    }`}
                  >
                    Loading Keywords...
                  </span>
                ) : (
                  <span
                    className={`p-1 font-normal text-sm italic text-gray-500  ${
                      lightMode ? 'hover:text-black' : 'hover:text-gray-400'
                    } hover:no-underline`}
                  >
                    No Keywords Available
                  </span>
                )}
              </div>
            }
            options={userLocationKeywordOptions}
            setLabel={setOverviewKeyword}
          />
        </div>
        <div className='space-y-5 sm:space-y-0 sm:mx-9 sm:grid sm:grid-cols-2 sm:gap-x-5 mt-4 sm:mt-3'>
          <OverviewInfoBox
            label={overviewFirstDoughnut}
            data={
              overviewFirstDoughnut === 'Leads Per Platform' ? (
                <LeadsDoughnutChart />
              ) : (
                <BudgetDoughnutChart />
              )
            }
            options={firstDoughnutOptions}
            setLabel={setFirstOverviewDoughnut}
          />
          <OverviewInfoBox
            label={overviewSecondDoughnut}
            data={
              overviewSecondDoughnut === 'Leads Per Device (Google)' ? (
                <LeadsDeviceDoughnutChart />
              ) : overviewSecondDoughnut === 'Leads Per Device (Microsoft)' ? (
                <LeadsDeviceBingDoughnutChart />
              ) : overviewSecondDoughnut === 'Budget Per Device (Google)' ? (
                <BudgetDeviceDoughnutChart />
              ) : (
                <BudgetDeviceBingDoughnutChart />
              )
            }
            options={secondDoughnutOptions}
            setLabel={setSecondDoughnut}
          />
        </div>
        <div className='mt-4 sm:mt-3 sm:mx-9'>
          <OverviewInfoBox
            label={overviewLineGraph}
            data={
              overviewLineGraph === 'Leads Per Coverage' ? (
                <LeadsLineGraph />
              ) : (
                <BudgetLineGraph />
              )
            }
            options={lineChartOptions}
            setLabel={setOverviewLineGraph}
          />
        </div>
        <div
          className={`p-3 mt-4 border-t border-b border-r border-l cursor-pointer transition duration-300 ${
            lightMode
              ? 'border-gray-900 text-gray-900 hover:bg-gray-50 hover:border-gray-300'
              : 'border-gray-50 text-gray-50 hover:bg-black hover:border-black'
          } w-min rounded shadow-md sm:mx-9`}
          onClick={scrollToTop}
        >
          <FaArrowUp className='text-xl' />
        </div>
      </div>
    </div>
  );
};

export default OverviewComponent;
