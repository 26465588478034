import React from 'react';
import { useRecoilValue } from 'recoil';
import { isAuthenticatedState, lightModeState } from '../../recoil/atoms';
import { Link } from 'react-router-dom';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const lightMode = useRecoilValue(lightModeState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);

  return (
    <footer
      className={`${
        lightMode ? 'text-black bg-gray-300' : 'text-white bg-green-600'
      } w-full sm:w-full body-font body shadow-inner p-3`}
    >
      <div className='flex items-center justify-between w-full'>
        <div className=' px-1 py-1 flex items-center sm:flex-row h-full'>
          <span className='ml-1 sm:ml-20 flex title-font font-medium items-center justify-center text-gray-900 hidden sm:inline'>
            <span
              className={`${
                !lightMode && 'text-white'
              } sm:ml-3 text-base sm:text-xl body`}
            >
              EAP
            </span>
          </span>
          <p
            className={`text-sm sm:ml-4 sm:pl-4 sm:border-l-2 ${
              lightMode ? 'border-gray-900' : 'border-gray-200'
            } sm:py-2 sm:mt-0 mt-1`}
          >
            © {year} Easy Agent PRO
          </p>
        </div>
        {isAuthenticated && (
          <Link
            to='/feedback'
            className={`hover:no-underline text-sm	${
              lightMode
                ? 'text-green-500 hover:text-green-600'
                : 'text-gray-300 hover:text-gray-200 '
            }`}
          >
            Send Feedback
          </Link>
        )}
      </div>
    </footer>
  );
};

export default Footer;
