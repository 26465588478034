import React from 'react';
import { useRecoilValue } from 'recoil';
import { pageState } from '../../recoil/atoms';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { Account } from '../../helpers/types';
import { getCoverageOption, month } from '../../helpers/dateUtils';

type Props = {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  coverage:
    | '90-Day'
    | '30-Day'
    | '3-Day'
    | 'Yesterday'
    | 'This Mo'
    | 'All Time'
    | 'Jan'
    | 'Feb'
    | 'Mar'
    | 'Apr'
    | 'May'
    | 'Jun'
    | 'Jul'
    | 'Aug'
    | 'Sept'
    | 'Oct'
    | 'Nov'
    | 'Dec';
  customOptions: {
    option: string;
    onClickOption: () => void;
  }[];
  onClickJan: () => void;
  onClickFeb: () => void;
  onClickMar: () => void;
  onClickApr: () => void;
  onClickMay: () => void;
  onClickJun: () => void;
  onClickJul: () => void;
  onClickAug: () => void;
  onClickSept: () => void;
  onClickOct: () => void;
  onClickNov: () => void;
  onClickDec: () => void;
  onClickAllTime: () => void;
  account?: Account;
};

const CoverageTemplate = ({
  isMenuOpen,
  setIsMenuOpen,
  coverage,
  customOptions,
  onClickJan,
  onClickFeb,
  onClickMar,
  onClickApr,
  onClickMay,
  onClickJun,
  onClickJul,
  onClickAug,
  onClickSept,
  onClickOct,
  onClickNov,
  onClickDec,
  onClickAllTime,
  account,
}: Props) => {
  const page = useRecoilValue(pageState);
  return (
    <div className='items-center w-full justify-center mobile:ml-2'>
      <label
        className={`border-l border-r border-t border-b 
           ${
             account && account.name === 'None'
               ? 'text-gray-400'
               : 'text-gray-900'
           } bg-white border-gray-500 h-full w-5/6 sm:w-5/12  py-2 px-2 focus:outline-none rounded-sm flex items-center justify-between font-semibold cursor-pointer text-xs sm:text-base`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {coverage === '30-Day'
          ? 'Last 30 Days'
          : coverage === '90-Day'
          ? 'Last 90 Days'
          : coverage === '3-Day'
          ? 'Last 3 Days'
          : coverage === 'This Mo'
          ? 'This Month'
          : coverage === 'All Time'
          ? 'All Time'
          : getCoverageOption(month.indexOf(coverage))}{' '}
        <MdOutlineArrowDropDown className='text-sm sm:text-lg' />
      </label>
      <div
        className={`coverage-template-custom ${
          page === 'home'
            ? 'max-h-1/6'
            : page === 'data'
            ? 'max-h-1/4 sm:max-h-2/5'
            : 'max-h-1/6 sm:max-h-1/4'
        } border-l border-r border-t border-b shadow-sm text-gray-900 bg-white border-gray-500 absolute overflow-y-scroll overflow-y-overlay z-30 rounded-sm pt-2 ${
          isMenuOpen ? '' : 'hidden'
        } -mt-2`}
      >
        <div
          className={`${
            isMenuOpen ? 'inline' : 'hidden'
          } w-full text-left font-semibold  max-h-1/4 text-xs sm:text-base`}
        >
          {customOptions.map(({ option, onClickOption }, index) => (
            <p
              className={`cursor-pointer pl-2  py-2 ${
                account && account.name === 'None'
                  ? 'text-gray-400'
                  : 'hover:bg-gray-200'
              }`}
              key={index}
              onClick={onClickOption}
            >
              {option}
            </p>
          ))}
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickJan}
          >
            {getCoverageOption(month.indexOf('Jan'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickFeb}
          >
            {getCoverageOption(month.indexOf('Feb'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickMar}
          >
            {getCoverageOption(month.indexOf('Mar'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickApr}
          >
            {getCoverageOption(month.indexOf('Apr'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickMay}
          >
            {getCoverageOption(month.indexOf('May'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickJun}
          >
            {getCoverageOption(month.indexOf('Jun'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickJul}
          >
            {getCoverageOption(month.indexOf('Jul'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickAug}
          >
            {getCoverageOption(month.indexOf('Aug'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickSept}
          >
            {getCoverageOption(month.indexOf('Sept'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickOct}
          >
            {getCoverageOption(month.indexOf('Oct'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickNov}
          >
            {getCoverageOption(month.indexOf('Nov'))}
          </p>
          <p
            className={`cursor-pointer pl-2  py-2 ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickDec}
          >
            {getCoverageOption(month.indexOf('Dec'))}
          </p>
          <p
            className={`cursor-pointer px-2 py-2 rounded-b ${
              account && account.name === 'None'
                ? 'text-gray-400'
                : 'hover:bg-gray-200'
            }`}
            onClick={onClickAllTime}
          >
            All Time
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoverageTemplate;
